import Appsignal from "@appsignal/javascript";
import { CREDENTIALS, ENV } from "@constants";

import { plugin as breadcrumbsPlugin } from "@appsignal/plugin-breadcrumbs-console";
import { plugin as networkBreadcrumbsPlugin } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as pathDecoratorPlugin } from "@appsignal/plugin-path-decorator";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";
import toast from "@components/Toast";

const appsignal = new Appsignal({
  key: CREDENTIALS.APPSIGNAL_FRONTEND_API_KEY,
  ignoreErrors: [/Can't verify CSRF token authenticity\./, /Illegal invocation/],
  revision: ENV.HEROKU_SLUG_COMMIT,
});

appsignal.use(breadcrumbsPlugin);
appsignal.use(networkBreadcrumbsPlugin);
appsignal.use(windowEventsPlugin);
appsignal.use(pathDecoratorPlugin);

appsignal.addDecorator((span) => {
  return span.setTags({ currentUserId: gon.current_user_id, url: window.location.href });
});

if (gon?.global?.env === "development") {
  appsignal.addOverride((span: any) => {
    console.error("Appsignal error: ", span._data);
    toast.error("Appsignal error reported. Printed details to console.");
    return span;
  });
}

export default appsignal;
