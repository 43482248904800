import { COLORS } from "@constants";
import styled from "styled-components";
import Icon from "../Icon";
import { DropdownOptionGroup } from "./DropdownOption";

const DropdownGroup = (data: DropdownOptionGroup) => (
  <>
    {data.hasDivider && <Divider />}
    <Label>
      {data.iconName && <Icon name={data.iconName} size="xs" color={COLORS.NEUTRAL_350} />}
      {data.label}
    </Label>
  </>
);

export default DropdownGroup;

const Divider = styled.div`
  width: calc(100% + 24px);
  height: 1px;
  background-color: ${COLORS.NEUTRAL_200};
  margin: 12px -12px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 5px;
`;
