import { Icon, ProfilePicture } from "@components/library";
import DROPDOWN_STYLES from "@components/library/Dropdowns/dropdownStyles";
import { COLORS, FONTS, STYLES } from "@constants";
import { User } from "@tsTypes/schema";
import { SponsorLicenseType } from "@tsTypes/sponsorProfiles";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import Select_, { MultiValueGenericProps, MultiValueRemoveProps, components } from "react-select";
import { default as CreatableSelect_ } from "react-select/creatable";
import styled from "styled-components";
import Validator from "validator";
import { AdminTag, LicenseTag } from "../Tags/users";

export interface UserOption {
  value: string; // email or user.id
  label: string; // email or name
  user: Pick<User, "id" | "email"> & {
    image: {
      medium: {
        url: string | null;
      };
    };
    name: string;
    profile_info: {
      license: SponsorLicenseType | null;
      isAdmin: boolean;
    };
  };
  __isNew__?: boolean;
}

// Customize label components both in value and menu
const formatOptionLabel = (option: UserOption, meta: any) => {
  if (meta.context === "menu") {
    if (option.__isNew__) {
      // Create label
      return <CreateLabel>{option.label}</CreateLabel>;
    }
    // Menu label
    return (
      <Option>
        <ProfilePicture size="sm" margin={"3px 0 0 "} user={option.user} />
        <div>
          <LabelContainer>
            <OptionName>{option.label}</OptionName>
            <TagContainer>
              {option.user.profile_info.isAdmin && <AdminTag />}
              {option.user.profile_info.license && (
                <LicenseTag type={option.user.profile_info.license} />
              )}
            </TagContainer>
          </LabelContainer>
          <OptionEmail>{option.value}</OptionEmail>
        </div>
      </Option>
    );
  }
  // Value label
  return (
    <LabelText error={!Validator.isEmail(option.value)} data-testid="user-dropdown-tag">
      {option.label}
    </LabelText>
  );
};

// Customize value remove icon
const MultiValueRemove = (props: MultiValueRemoveProps<UserOption>) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValueRemove {...props}>
      <Icon
        name="X"
        color={!Validator.isEmail(props.data.value) ? COLORS.RED : COLORS.BLACK}
        size="xs"
      />
    </components.MultiValueRemove>
  );
};

// Customize value label with premium icon
const MultiValueLabel = (props: MultiValueGenericProps<UserOption>) => {
  const { children, ...otherProps } = props;
  // TODO: (justinpchang) Show premium tooltip
  return <components.MultiValueLabel {...otherProps}>{children}</components.MultiValueLabel>;
};

interface DropdownProps {
  options: UserOption[];
  value: UserOption[];
  placeholder?: string;
  maxMenuHeight?: string;
  maxValueContainerHeight?: string;
  creatable?: boolean;
  onChange?: (any) => void;
  setError?: Dispatch<SetStateAction<string>>;
  formatCreateLabel?: (input: string) => string;
  isPortal?: boolean;
}

const UserDropdown: FunctionComponent<DropdownProps> = ({
  options,
  value,
  onChange,
  maxMenuHeight = "250px",
  maxValueContainerHeight = "unset",
  formatCreateLabel,
  placeholder = "Search for teammates by name or email",
  creatable = false,
  isPortal = false,
}) => {
  const Container = creatable ? CreatableSelect : Select;
  return (
    <div data-testid="user-dropdown">
      <Container
        isMulti
        isClearable={false}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        classNamePrefix="Select"
        maxMenuHeight={maxMenuHeight}
        maxValueContainerHeight={maxValueContainerHeight}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          MultiValueRemove,
          MultiValueLabel,
        }}
        formatOptionLabel={formatOptionLabel}
        formatCreateLabel={formatCreateLabel}
        noOptionsMessage={() => null}
        menuPosition={isPortal && "absolute"}
        menuPortalTarget={isPortal && document.body}
        styles={{
          multiValue: (base) => ({
            ...base,
            borderRadius: "100px",
            padding: "0 3px",
            fontSize: 14,
          }),
          multiValueRemove: (base) => ({
            ...base,
            borderRadius: "100px",
            "&:hover": {
              backgroundColor: COLORS.NEUTRAL_200,
            },
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
    </div>
  );
};

const Select = styled(Select_)`
  ${DROPDOWN_STYLES};
  .Select__control {
    ${({ maxValueContainerHeight }) =>
      maxValueContainerHeight && `max-height: ${maxValueContainerHeight};`}
    overflow-y: auto;
  }
  .Select__menu-list {
    ${({ maxMenuHeight }) => `max-height: ${maxMenuHeight}`};
  }
`;

const CreatableSelect = styled(CreatableSelect_)`
  ${DROPDOWN_STYLES};
  .Select__control {
    ${({ maxValueContainerHeight }) =>
      maxValueContainerHeight && `max-height: ${maxValueContainerHeight};`}
    overflow-y: auto;
  }
  .Select__menu-list {
    ${({ maxMenuHeight }) => `max-height: ${maxMenuHeight}`};
  }
`;

const Option = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-gap: 0 10px;
  align-items: flex-start;
  margin: 10px 8px;
`;

const OptionName = styled.div`
  flex-grow: 1;
  ${FONTS.SEMIBOLD_2}
  ${STYLES.ONE_LINE}
`;

const OptionEmail = styled.div`
  ${FONTS.REGULAR_3}
  ${STYLES.ONE_LINE}
`;

const LabelText = styled.p`
  ${(props) => props.error && `color: ${COLORS.RED}`};
`;

const CreateLabel = styled.span`
  ${FONTS.REGULAR_2}
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  pointer-events: none;
`;

export default UserDropdown;
