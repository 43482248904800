import { InfoTag } from "@components/library";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { ReactNode, useState } from "react";
import styled, { css } from "styled-components";

export interface TabsProps {
  activeTab: string;
  setActiveTab: (newTab: string) => void;
  tabs: {
    key: string;
    label: string | ReactNode;
    count?: number;
    withNewTag?: boolean;
    onClick?: () => void;
    "data-testid"?: string;
  }[];
  variant?: "normal" | "attention";
  activeColor?: string;
  font?: string;
  tabDistance?: string;
  margin?: string | 0;
}

const Tabs = ({
  activeTab,
  setActiveTab,
  tabs,
  variant = "normal",
  activeColor,
  font = FONTS.SEMIBOLD_2,
  tabDistance = "24px",
  margin,
}: TabsProps) => {
  const [hoveredTab, setHoveredTab] = useState<string | null>();

  return (
    <Container>
      <Shadow />
      <TabsContainer margin={margin} tabDistance={tabDistance}>
        {tabs.map((tab) => {
          const isActive = activeTab === tab.key;
          return (
            <TabWrapper key={tab.key}>
              <Tab
                type="button"
                isActive={isActive}
                onClick={() => {
                  setActiveTab(tab.key);
                  if (tab.onClick) tab.onClick();
                }}
                onMouseEnter={() => setHoveredTab(tab.key)}
                onMouseLeave={() => setHoveredTab(null)}
                variant={variant}
                activeColor={activeColor}
                font={font}
                data-testid={tab["data-testid"]}
              >
                {tab.label}
                {typeof tab.count === "number" && (
                  <InfoTag
                    backgroundColor={(() => {
                      if (isActive) {
                        return (
                          activeColor ?? (variant === "normal" ? COLORS.BLACK : COLORS.ORANGE_900)
                        );
                      } else if (hoveredTab === tab.key) {
                        return variant === "normal" ? COLORS.NEUTRAL_500 : COLORS.ORANGE_900;
                      }
                      return variant === "normal" ? COLORS.NEUTRAL_400 : COLORS.ORANGE;
                    })()}
                  >
                    {tab.count}
                  </InfoTag>
                )}
              </Tab>
              {tab.withNewTag && (
                <NewTagContainer>
                  <InfoTag accent>NEW</InfoTag>
                </NewTagContainer>
              )}
            </TabWrapper>
          );
        })}
      </TabsContainer>
    </Container>
  );
};

export default Tabs;

const Container = styled.div`
  position: relative;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: ${({ tabDistance }) => tabDistance};
  ${({ margin }) => margin && `margin: ${margin}`};
  overflow-x: auto;
  min-height: 16px;

  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    padding-right: 40px;

    /* Hide horizontal scrollbar for mobile */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TabWrapper = styled.div`
  display: flex;
  white-space: nowrap;
`;
const Tab = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 5px;
  border: none;
  border-bottom: ${({ isActive, variant, activeColor }) =>
    `2px solid ${(() => {
      if (isActive) {
        if (activeColor) return activeColor;
        if (variant === "normal") return COLORS.BLACK;
        if (variant === "attention") return COLORS.ORANGE_900;
      } else {
        return "transparent";
      }
    })()}`};
  background-color: transparent;
  ${({ font }) => font};
  color: ${({ isActive, variant, activeColor }) => {
    if (isActive) {
      if (activeColor) return activeColor;
      if (variant === "normal") return COLORS.BLACK;
      if (variant === "attention") return COLORS.ORANGE_900;
    }
    return variant === "normal" ? COLORS.NEUTRAL_400 : COLORS.ORANGE;
  }};
  ${({ isActive, variant }) =>
    !isActive &&
    css`
      &:hover {
        color: ${variant === "normal" ? COLORS.NEUTRAL_500 : COLORS.ORANGE_900};
        border-bottom: 2px solid ${variant === "normal" ? COLORS.NEUTRAL_500 : COLORS.ORANGE_900};
      }
    `}
  &:active {
    outline: none;
  }
`;
const NewTagContainer = styled.div`
  display: flex;
  margin: 4px 0 0 0;
`;

const Shadow = styled.div`
  display: none;
  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    display: block;

    pointer-events: none;

    position: absolute;
    top: 0;
    bottom: 0;
    right: -1px; // hack because 1px of the content under the shadow is visible on the right side

    width: 100%;
    height: 101%; // hack because 1px of the content under the shadow is visible on the bottom

    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 83%, rgb(255, 255, 255) 93%);
  }
`;
