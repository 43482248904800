import toast from "@components/Toast";
import { t } from "@utils/i18n";

export const authenticityToken = (): string | undefined => {
  const token = document.querySelector('meta[name="csrf-token"]');
  if (token && token instanceof window.HTMLMetaElement) {
    return token.content;
  }
  return undefined;
};

export const getCookie = (name) => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

export const getUrlParameter = (name) => {
  const trimmed = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + trimmed + "=([^&#]*)");
  const results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const toastErrorHandler = (error, _variables?, _context?): void => {
  toast.error(
    t(["error", error.status ? "default_with_code" : "default"], { status: error.status })
  );
};
