import styled from "styled-components";

import { FONTS } from "@constants";
import Button from "../Buttons/Button";
import { IconName } from "../Icon/Icon";
import BlankModal, { BlankModalProps } from "../ModalBases/BlankModal";

interface Props {
  confirmButtonText?: string;
  onConfirm: () => void;
  confirmIconName: IconName;
  type?: "positive" | "negative";
  "data-testid"?: string;
}

const SimpleConfirmationModal = ({
  children,
  isOpen,
  onConfirm,
  confirmIconName,
  onClose,
  headerText,
  subHeaderText,
  confirmButtonText,
  type = "negative",
  "data-testid": dataTestId,
  zIndexBase = 1000,
}: Props &
  Pick<
    BlankModalProps,
    "isOpen" | "onClose" | "headerText" | "subHeaderText" | "children" | "zIndexBase"
  >) => {
  if (!isOpen) return null;

  return (
    <BlankModal
      isOpen={isOpen}
      onClose={onClose}
      width="542px"
      closeOnDimmerClick={false}
      disableBackgroundScroll
      zIndexBase={zIndexBase}
    >
      <Content data-testid={dataTestId}>
        <HeaderText>{headerText}</HeaderText>
        <SubHeader>{subHeaderText}</SubHeader>
        {children}
        <ButtonContainer>
          <Button onClick={onClose} data-testid="cancel-button">
            Cancel
          </Button>
          <Button
            iconName={confirmIconName}
            variant={`${type === "negative" ? "destructive-" : ""}ghost`}
            onClick={onConfirm}
            data-testid="confirm-button"
          >
            {confirmButtonText ?? "Confirm"}
          </Button>
        </ButtonContainer>
      </Content>
    </BlankModal>
  );
};

export default SimpleConfirmationModal;

const Content = styled.div`
  padding: 72px 80px 48px;
`;

const HeaderText = styled.div`
  ${FONTS.HEADING_3_SEMIBOLD}
  margin: 0 0 16px;
`;

const SubHeader = styled.div`
  ${FONTS.REGULAR_1}
`;

const ButtonContainer = styled.div`
  margin-top: 56px;
  width: 100%;

  display: flex;
  gap: 16px;
`;
