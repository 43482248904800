/* eslint no-unused-vars: 0 */

/**
 * This file was automatically generated by lib/tasks/ruby_to_typescript.rake
 * DO NOT MODIFY IT BY HAND!
 */
const COLORS = {
  BLACK: "#232D38",
  BLUE_450: "#2BB3F6",
  BLUE_500: "#009CEA",
  BLUE_600: "#0088D6",
  BLUE_650: "#0C76C4",
  BLUE_700: "#006AB8",
  BLUE_750: "#0068A7",
  BLUE_900: "#004980",
  BLUE_LIGHT_50: "#F9FDFF",
  BLUE_LIGHT_100: "#F5FBFF",
  BLUE_LIGHT_250: "#F2F9FF",
  BLUE_LIGHT_300: "#EEF6FD",
  BLUE_LIGHT_350: "#DEF0FF",
  BLUE_LIGHT_400: "#D9EEFF",
  BLUE_LIGHT_500: "#C6E5FF",
  BLUE_LIGHT_600: "#93CDFF",
  BLUE_LIGHT_650: "#7DC2FD",
  BLUE_LIGHT_700: "#6BB1EC",
  GREEN: "#1A9A1F",
  GREEN_50: "#EFFEF1",
  GREEN_100: "#DDF4DE",
  GREEN_200: "#CEF6D0",
  GREEN_300: "#B0F2B2",
  GREEN_400: "#8CED90",
  GREEN_500: "#59CD5E",
  GREEN_600: "#38B83D",
  GREEN_700: "#1A9A1F",
  GREEN_750: "#108815",
  GREEN_800: "#007C01",
  HALO_BLUE: "#009CEA",
  NEUTRAL_0: "#FFFFFF",
  NEUTRAL_50: "#F8F9FC",
  NEUTRAL_100: "#EFF3F7",
  NEUTRAL_200: "#E4EBF1",
  NEUTRAL_250: "#DCE2E8",
  NEUTRAL_300: "#D1D7DB",
  NEUTRAL_350: "#C5CAD2",
  NEUTRAL_400: "#9CA3AF",
  NEUTRAL_500: "#6B7280",
  NEUTRAL_600: "#4B5563",
  NEUTRAL_700: "#374151",
  NEUTRAL_800: "#232D38",
  NEUTRAL_900: "#1E252E",
  NEUTRAL_950: "#111827",
  ORANGE: "#D47200",
  ORANGE_50: "#FFF9E9",
  ORANGE_100: "#FFF3D3",
  ORANGE_200: "#FFEAB1",
  ORANGE_300: "#FFD88B",
  ORANGE_400: "#FCC457",
  ORANGE_500: "#FAB228",
  ORANGE_600: "#F89B30",
  ORANGE_700: "#E7820E",
  ORANGE_800: "#D47200",
  ORANGE_900: "#AF5000",
  PINK_50: "#FDF5FD",
  PINK_100: "#F9E1FA",
  PINK_200: "#F6CFEC",
  PINK_300: "#E699C9",
  PINK_400: "#DC75B1",
  PINK_500: "#D35198",
  PINK_600: "#C92D80",
  PINK_700: "#B22474",
  PINK_800: "#9A1A67",
  RED: "#DE3957",
  RED_50: "#FFF6F8",
  RED_100: "#FFE6EB",
  RED_200: "#FFCED6",
  RED_300: "#FDBFC9",
  RED_500: "#DE3957",
  RED_600: "#C9334E",
  RED_700: "#AC2B42",
  WHITE: "#FFFFFF",
};

export default COLORS;
