export default {
  SHADOW_A: "0px 1px 5px 0px #1D477B1F, 0px 5px 10px 0px #1D477B0A",
  SHADOW_B: "0px 10px 22px rgba(29, 71, 123, 0.08), 0px 2px 12px rgba(29, 71, 123, 0.08)",
  SHADOW_C: "0px 4px 10px rgba(28, 50, 79, 0.28)",
  SHADOW_D: "0px 6px 24px -4px #1C324F61",
  SHADOW_E: "0px 15.1852px 48.5926px -6.07407px rgba(1, 106, 170, 0.15)",
  SHADOW_CARD: "0px 1px 3px 0px rgba(4, 11, 19, 0.06)",
  ONE_LINE: `
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  `,
  // specify max width element for this to work
  ONE_LINE_ALT: `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `,
  TWO_LINES: `
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  `,
  THREE_LINES: `
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  `,
  HERO_MEDIA_QUERY: `@media screen and (max-width: 1440px)`,
};
