const PORTAL_ID = "6895929";
// Research follow
const FORM_ID = "b7f36ac2-2f23-4891-8713-22f1fc2eb7a5";

const HUBSPOT = {
  PORTAL_ID,
  FORM_ID,
};

export default HUBSPOT;
