import toast from "@components/Toast";
import { ConnectionType } from "@tsTypes/__generated__/enums/connection_type.js";
import { CompanyNetworkScientistsAccountSettingsResponse } from "@tsTypes/company_network_scientists/account_settings";
import {
  CompanyNetworkScientistPartial,
  CompanyNetworkScientistsCreateResponse,
} from "@tsTypes/index";
import appsignal from "@utils/appsignal";
import { t } from "@utils/i18n";
import { search_suggest_company_network_scientists_path } from "../routes/routes.js";
import request from "./request";

export const requestToJoinCompanyNetwork = async (
  companyId: number
): Promise<CompanyNetworkScientistsCreateResponse> => {
  const response = await request.post("/company_network_scientists", {
    company_id: companyId,
    is_from_digest: false,
  });
  return response.data;
};

export const requestToJoinCompanyNetworkFromDigest = async (
  companyIdentifier: string
): Promise<void> => {
  try {
    const response = await request.post("/company_network_scientists", {
      company_identifier: companyIdentifier,
      is_from_digest: true,
    });
    const {
      company_network_scientist: { company_name },
    } = response.data;
    toast.success(
      t("models.company_network_scientist.sponsor_digest.join_success", { company_name })
    );
  } catch (error) {
    switch (error.response?.status) {
      case 401:
        break;
      case 409:
        toast.success(
          t("models.company_network_scientist.sponsor_digest.join_duplicate", {
            company_name: error.response.data.company_name,
          })
        );
        break;
      default:
        toast.error(t("error.default"));
        appsignal.sendError(error, (span) => {
          span.setAction("companyNetworkScientists#requestToJoinCompanyNetworkFromDigest");
          span.setTags({
            companyIdentifier,
          });
        });
    }
  }
};

export const getCompanyNetworkScientist = async ({
  scientistProfileId,
  companyId,
}): Promise<CompanyNetworkScientistPartial | null> => {
  try {
    const response = await request.get("/company_network_scientist", {
      params: {
        scientist_profile_id: scientistProfileId,
        company_id: companyId,
      },
    });

    return response?.data;
  } catch (error) {
    switch (error.response?.status) {
      case 404:
        break;
      default:
        appsignal.sendError(error, (span) => {
          span.setAction("getCompanyNetworkScientist");
          span.setTags({
            scientistProfileId,
            companyId,
          });
        });
    }

    return null;
  }
};

export const searchSuggest = async (query: string, connectionType: ConnectionType | undefined) => {
  const response = await request.get(search_suggest_company_network_scientists_path(), {
    params: {
      query,
      connection_type: connectionType,
    },
  });
  return response.data;
};

export const getCompanyNetworkScientistsForAccountSettings =
  async (): Promise<CompanyNetworkScientistsAccountSettingsResponse> => {
    const response = await request.get("/company_network_scientists", {
      params: {
        for_account_settings: true,
      },
    });
    return response.data;
  };

export const inviteScientistsToCompanyNetwork = async (
  emails: string[],
  message: string
): Promise<{
  immediately_added: number;
  sent_invitation: number;
  already_in_network: number;
}> => {
  const response = await request.post("/notifications/invite_scientists_to_network", {
    emails: emails,
    message: message,
  });
  return response.data;
};

export const addScientistToCompanyNetwork = async (
  scientistProfileId: string
): Promise<CompanyNetworkScientistPartial> => {
  const response = await request.post(
    "/company_network_scientist/sponsor_add_scientist_to_network",
    {
      scientist_profile_id: scientistProfileId,
    }
  );
  return response.data;
};
