export enum SponsorRole {
  SME = "Subject Matter Expert",
  EXTERNAL_INNOVATION = "External Innovation",
  C_LEVEL = "C-Level",
  CORPORATE_DEVELOPMENT = "Corporate Development",
  VENTURE_CAPITAL = "Venture Capital",
}

export enum SponsorLicenseType {
  PREMIUM = "premium",
  STANDARD = "standard",
}
