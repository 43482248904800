/* eslint no-unused-vars: 0 */

/**
 * This file was automatically generated by ruby_to_typescript.rake.
 * Do not modify it by hand.
 */
export enum TourIdentifier {
  PROPOSAL_REVIEW_SYSTEM_2024_01_22 = "PROPOSAL_REVIEW_SYSTEM_2024_01_22",
  PROPOSAL_REVIEW_SYSTEM_FEEDBACK_DEADLINE_V2_2024_10_31 = "PROPOSAL_REVIEW_SYSTEM_FEEDBACK_DEADLINE_V2_2024_10_31",
  PROPOSAL_EVALUATION_2024_02_29 = "PROPOSAL_EVALUATION_2024_02_29",
  SCIENTIST_NETWORK_2024_03_01 = "SCIENTIST_NETWORK_2024_03_01",
  PROPOSAL_EVALUATION_SCORECARD_FORM_2024_11_06 = "PROPOSAL_EVALUATION_SCORECARD_FORM_2024_11_06",
  PROPOSAL_EVALUATION_SIMPLE_FORM_2024_11_06 = "PROPOSAL_EVALUATION_SIMPLE_FORM_2024_11_06",
  PROPOSAL_REVIEW_SYSTEM_FILTER_2024_11_06 = "PROPOSAL_REVIEW_SYSTEM_FILTER_2024_11_06",
}
