import Icon from "@components/library/Icon";
import { COLORS, FONTS } from "@constants";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { IconName } from "../Icon/Icon";

export interface BaseButtonProps {
  id?: string;
  name?: string;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  form?: string;
  onClick?: (...args: any[]) => any;
  onMouseEnter?: (...args: any[]) => any;
  onMouseLeave?: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  margin?: string | 0;
  "data-testid"?: string;
}

export interface ButtonProps extends BaseButtonProps {
  variant?:
    | "primary"
    | "secondary"
    | "secondary-dark"
    | "ghost"
    | "destructive-primary"
    | "destructive-secondary"
    | "destructive-ghost"
    | "orange"
    | "marketing";
  size?: "xs" | "sm" | "md" | "lg";
  // This sets a min-width in case the button is intended to extend past its contents.  A minimum padding will be maintained
  width?: string;
  /**
   * Add a `to` prop similar to a Link tag. This will use a <a> tag instead of a button.
   */
  to?: string;
  isExternalLink?: boolean;
  openInNewTab?: boolean;
  iconName?: IconName;
  iconPosition?: "left" | "right";
  children: any;
}

const Button = ({
  variant = "primary",
  to,
  isExternalLink = false,
  openInNewTab = false,
  size = "md",
  width,
  margin = 0,
  iconName,
  iconPosition = "left",
  children,
  id,
  name,
  type = "submit",
  disabled,
  form,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  "data-testid": dataTestId,
}: ButtonProps) => {
  let iconColor = COLORS.WHITE;

  if (variant === "destructive-ghost" || variant === "destructive-secondary") {
    iconColor = COLORS.RED;
  } else if (variant === "secondary" || variant === "ghost") {
    iconColor = COLORS.HALO_BLUE;
  }

  const StyledComponent = to ? StyledLink : StyledButton;

  return (
    <StyledComponent
      to={!isExternalLink && to}
      href={isExternalLink ? to : undefined}
      as={isExternalLink ? "a" : undefined}
      target={isExternalLink && openInNewTab ? "_blank" : undefined}
      rel={isExternalLink && openInNewTab ? "noopener noreferrer" : undefined}
      variant={variant}
      size={size}
      width={width}
      margin={margin}
      id={id}
      name={name}
      // Seems like button type has issues with default values and ESLint
      // https://github.com/yannickcr/eslint-plugin-react/issues/1555#issuecomment-377339656
      /* eslint-disable react/button-has-type */
      type={type}
      disabled={disabled}
      form={form}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid={dataTestId}
    >
      <ButtonContent variant={variant} size={size} iconPosition={iconPosition}>
        {iconName ? (
          <Icon
            name={iconName}
            size="md"
            color={iconColor}
            margin={iconPosition === "left" ? "0 4px 0 -4px" : "0 -8px 0 4px"}
          />
        ) : null}
        {children}
      </ButtonContent>
    </StyledComponent>
  );
};

export default Button;

export const BaseStyles = css`
  height: ${({ size }) => {
    switch (size) {
      case "xs":
        return "24px";
      case "sm":
        return "36px";
      case "l":
        return "64px";
      default:
        return "42px";
    }
  }};
  min-width: ${({ width }) => width ?? "initial"};
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 0;
  margin: ${({ margin }) => margin};

  &:hover > div {
    background-color: ${({ variant }) => {
      switch (variant) {
        case "destructive-primary": {
          return COLORS.RED_600;
        }
        case "destructive-secondary": {
          return COLORS.RED_200;
        }
        case "destructive-ghost": {
          return COLORS.RED_100;
        }
        case "secondary":
        case "secondary-dark": {
          return COLORS.BLUE_LIGHT_400;
        }
        case "ghost": {
          return COLORS.BLUE_LIGHT_300;
        }
        case "orange": {
          return COLORS.ORANGE_700;
        }
        case "marketing": {
          return COLORS.PINK_700;
        }
        default: {
          return COLORS.BLUE_600;
        }
      }
    }};
  }

  &:active {
    outline: none;

    & > div {
      background-color: ${({ variant }) => {
        switch (variant) {
          case "destructive-primary": {
            return COLORS.RED_700;
          }
          case "destructive-secondary": {
            return COLORS.RED_300;
          }
          case "destructive-ghost": {
            return COLORS.RED_200;
          }
          case "secondary": {
            return COLORS.BLUE_LIGHT_500;
          }
          case "secondary-dark": {
            return COLORS.BLUE_LIGHT_350;
          }
          case "ghost": {
            return COLORS.BLUE_LIGHT_400;
          }
          case "orange": {
            return COLORS.ORANGE;
          }
          case "marketing": {
            return COLORS.PINK_600;
          }
          default: {
            return COLORS.BLUE_650;
          }
        }
      }};
    }
  }

  &:focus:not(:active) {
    outline: 2px solid ${COLORS.WHITE};
    box-shadow: 0 0 0 3px
      ${({ variant }) => (variant.includes("destructive") ? COLORS.RED : COLORS.HALO_BLUE)};
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

const StyledButton = styled.button`
  ${BaseStyles}
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: ${({ iconPosition }) => (iconPosition === "left" ? "row" : "row-reverse")};
  justify-content: center;
  align-items: center;
  background-color: ${({ variant }) => {
    switch (variant) {
      case "destructive-primary": {
        return COLORS.RED;
      }
      case "destructive-secondary": {
        return COLORS.RED_100;
      }
      case "secondary": {
        return COLORS.BLUE_LIGHT_300;
      }
      case "secondary-dark": {
        return COLORS.BLUE_LIGHT_350;
      }
      case "ghost":
      case "destructive-ghost": {
        return "transparent";
      }
      case "orange": {
        return COLORS.ORANGE_700;
      }
      case "marketing": {
        return COLORS.PINK_600;
      }
      default: {
        return COLORS.HALO_BLUE;
      }
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case "destructive-secondary":
      case "destructive-ghost": {
        return COLORS.RED;
      }
      case "secondary-dark": {
        return COLORS.HALO_BLUE;
      }
      case "secondary":
      case "ghost": {
        return COLORS.HALO_BLUE;
      }
      default: {
        return COLORS.WHITE;
      }
    }
  }};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  ${({ size }) => {
    switch (size) {
      case "md":
        return FONTS.BUTTON_1;
      case "lg":
        return FONTS.HEADING_5_MEDIUM;
      default:
        return FONTS.BUTTON_2;
    }
  }}
  padding: ${({ size }) => (size === "md" ? "0 24px" : "0 20px")};
  transition: background-color 0.1s ease;
`;

// TODO: (overload119) Add support for various font sizes on StyledLink.
const StyledLink = styled(Link)`
  display: inline-block;
  ${BaseStyles}
  &:hover {
    text-decoration: none;
  }
  ${ButtonContent} {
    &:hover {
      text-decoration: none;
    }
  }
`;
