import { COLORS } from "@constants";
import styled from "styled-components";
import Icon from "../Icon/Icon";

export type SortDirection = "NONE" | "DESC" | "ASC";

interface SortState {
  color: string;
  inverted: boolean;
  next: SortDirection;
}

const SORT_STATES = new Map<SortDirection, SortState>([
  [
    "NONE",
    {
      color: COLORS.NEUTRAL_300,
      inverted: false,
      next: "DESC",
    },
  ],
  [
    "DESC",
    {
      color: COLORS.BLACK,
      inverted: false,
      next: "ASC",
    },
  ],
  [
    "ASC",
    {
      color: COLORS.BLACK,
      inverted: true,
      next: "NONE",
    },
  ],
]);

interface SortButtonProps {
  sortDirection: SortDirection;
  setSortDirection: (newSortDirection: SortDirection) => any;
  margin?: string;
}

const SortButton = ({ sortDirection = "NONE", setSortDirection, margin }: SortButtonProps) => {
  const state = SORT_STATES.get(sortDirection);
  return (
    <IconContainer
      inverted={state!.inverted}
      onClick={() => setSortDirection(state!.next)}
      title="Change Sort"
    >
      <Icon size="xs" name="Sort" margin={margin} color={state!.color} />
    </IconContainer>
  );
};

export default SortButton;

const IconContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  ${({ inverted }) => inverted && "transform: scaleY(-1);"}
`;
