import { request_for_expert_proposal_path, tabbed_marketplace_path } from "@routes/routes";
import { RequestableType } from "@tsTypes/requests";

export const formatPathForRouter = (url) => {
  return url.toString().replace("(.:format)", "");
};

// Helper to manage Flipper-conditional marketplace route
export const marketplacePath = (tab = "requests", options = {}) => {
  return tabbed_marketplace_path(tab, options);
};

export const proposalyPagePath = (slug: string, requestable_type: RequestableType) => {
  switch (requestable_type) {
    case RequestableType.REQUEST_FOR_STARTUP:
      return `/startup_program_proposal/${slug}`;
    case RequestableType.REQUEST_FOR_EXPERT:
      return request_for_expert_proposal_path(slug);
    default:
      return `/proposal/${slug}`;
  }
};
