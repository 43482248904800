import Tooltip from "@components/library/Tooltips/Tooltip";
import { COLORS } from "@constants";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  content: string;
  width: string;
  children: ReactNode;
}
export function LicenseTagTooltip({ content, children, width = "190px" }: Props) {
  return (
    <div>
      <Tooltip shouldFlex tooltipWidth={width} content={content}>
        {children}
      </Tooltip>
    </div>
  );
}

export const WhiteLink = styled(Link)`
  color: ${COLORS.WHITE};
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.WHITE};
  }
`;
