/* WINDOW DIMENSIONS */
const EXTRA_WIDE = 1920;
const DESKTOP = 1280;
const TABLET_LANDSCAPE = 991;
const TABLET = 767;
const MOBILE = 479;
/* MEDIA QUERIES */
const EXTRA_WIDE_MEDIA_QUERY = `@media screen and (min-width: ${EXTRA_WIDE}px)`;
const DESKTOP_MEDIA_QUERY = `@media screen and (max-width: ${DESKTOP}px)`;
const TABLET_LANDSCAPE_MEDIA_QUERY = `@media screen and (max-width: ${TABLET_LANDSCAPE}px)`;
const TABLET_MEDIA_QUERY = `@media screen and (max-width: ${TABLET}px)`;
const MOBILE_MEDIA_QUERY = `@media screen and (max-width: ${MOBILE}px)`;

// From Erik's research of active users, currently only used for showing
// a mini version of the RFP page as a preview at the end of the RFP form
const BASE_WINDOW_WIDTH = 1350;

// New responsiveness approach based on design system
const PAGE_SIDE_PADDING = 82;
const TABLET_SIDE_PADDING = 16;
const DESKTOP_MIN_CONTENT_WIDTH = 1216;
const DESKTOP_MIN_CONTENT_WIDTH_MEDIA_QUERY = `@media screen and (max-width: ${DESKTOP_MIN_CONTENT_WIDTH}px)`;
const DESKTOP_MIN_WIDTH = DESKTOP_MIN_CONTENT_WIDTH + 2 * PAGE_SIDE_PADDING;
const DESKTOP_MIN_WIDTH_MEDIA_QUERY = `@media screen and (max-width: ${DESKTOP_MIN_WIDTH}px)`;
// Some components/pages can stretch beyond this on a case-by-case basis
const DEFAULT_MAX_WIDTH = 1680;

export default {
  EXTRA_WIDE,
  DESKTOP,
  TABLET_LANDSCAPE,
  TABLET,
  MOBILE,
  EXTRA_WIDE_MEDIA_QUERY,
  DESKTOP_MEDIA_QUERY,
  TABLET_LANDSCAPE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  BASE_WINDOW_WIDTH,
  PAGE_SIDE_PADDING,
  TABLET_SIDE_PADDING,
  DESKTOP_MIN_CONTENT_WIDTH,
  DESKTOP_MIN_CONTENT_WIDTH_MEDIA_QUERY,
  DESKTOP_MIN_WIDTH,
  DESKTOP_MIN_WIDTH_MEDIA_QUERY,
  DEFAULT_MAX_WIDTH,
};
