import { Icon, InfoTag } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { Dispatch, ReactNode, SetStateAction } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  label: string;
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  areAllSelected: boolean;
  tagText: string; // Content of the tag to the right of the input label
  shouldShowInfoText?: boolean;
  infoText?: string;
}

const CollapsibleInputWrapper = ({
  children,
  label,
  isExpanded,
  setIsExpanded,
  areAllSelected,
  tagText,
  shouldShowInfoText,
  infoText,
}: Props) => {
  return (
    <Container>
      <Top top="button" onClick={() => setIsExpanded(!isExpanded)}>
        {label}
        <InfoTag marginLeft="8px" backgroundColor={COLORS.BLACK}>
          {areAllSelected ? "All" : tagText}
        </InfoTag>
        <Icon name={isExpanded ? "Chevron Up" : "Chevron Down"} margin="0 0 0 auto" />
      </Top>
      {isExpanded && (
        <>
          {children}
          {shouldShowInfoText && infoText && (
            <Info>
              <Icon name="Info Small" size="xs" margin="2px 4px 0 0" />
              {infoText}
            </Info>
          )}
        </>
      )}
    </Container>
  );
};

export default CollapsibleInputWrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px;
  background-color: ${COLORS.NEUTRAL_50};
  border-radius: 8px;
  color: ${COLORS.BLACK};
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  ${FONTS.SEMIBOLD_1};
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
const Info = styled.div`
  display: flex;
  ${FONTS.REGULAR_3};
`;
