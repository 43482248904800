import { Icon } from "@components/library";
import type { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { COLORS, FONTS } from "@constants";
import { ReactNode } from "react";
import Select, { ClearIndicatorProps, components } from "react-select";
import styled from "styled-components";
import { LabelText, MultiSelectDropdownBaseStyles, Optional } from "./MultiSelectDropdown";
import DROPDOWN_STYLES from "./dropdownStyles";

interface Props {
  value: DropdownOption | null;
  options: DropdownOption[];
  onChange: (...args: any[]) => any;
  placeholder: string;
  label?: string;
  labelFont?: string;
  isOptional?: boolean;
  isClearable?: boolean;
  createOptionComponent?: ReactNode;
  onCreate?: (...args: any[]) => any;
  menuPlacement?: "bottom" | "auto" | "top";
  maxValueContainerHeight?: string;
  maxMenuHeight?: string;
  helpText?: string;
  errors?: { hasError: boolean; errorMessage: string }[];
}

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="X" size="xs" />
    </components.ClearIndicator>
  );
};

const SelectOrCreateDropdown = ({
  value,
  options,
  onChange,
  placeholder,
  label,
  labelFont = FONTS.SEMIBOLD_2,
  isOptional = false,
  isClearable = false,
  createOptionComponent = (
    <CreateLabel>
      <Icon name="Add" color={COLORS.HALO_BLUE} margin="0 10px 0 0" />
      Create new
    </CreateLabel>
  ),
  onCreate,
  menuPlacement = "auto",
  maxValueContainerHeight = "unset",
  maxMenuHeight = "250px",
  helpText,
  errors,
}: Props) => {
  const handleChange = (option) => {
    if (option?.value === "_CREATE" && onCreate) {
      onCreate();
    }
    onChange(option);
  };

  const hasAnyError = errors?.map((error) => error.hasError).includes(true);

  const optionsWithCreate = onCreate
    ? [
        ...options,
        {
          value: "_CREATE",
          label: createOptionComponent,
        },
      ]
    : options;

  const dropdown = (
    <Dropdown
      classNamePrefix="Select"
      value={value ?? null}
      options={optionsWithCreate}
      onChange={handleChange}
      hideSelectedOptions={false}
      isSearchable={false}
      isClearable={isClearable}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      maxMenuHeight={maxMenuHeight}
      maxValueContainerHeight={maxValueContainerHeight}
      hasError={hasAnyError}
      hasCreateOption={Boolean(onCreate)}
      components={{
        ClearIndicator,
      }}
    />
  );

  return (
    <div>
      {label ? (
        <DropdownLabel labelFont={labelFont}>
          <LabelText hasError={hasAnyError}>
            {label}
            {isOptional && <Optional>Optional</Optional>}
          </LabelText>
          {dropdown}
        </DropdownLabel>
      ) : (
        dropdown
      )}
      {Number(helpText?.length) > 0 && <HelpText>{helpText}</HelpText>}
      {hasAnyError &&
        errors?.map(
          (error) =>
            error.hasError && (
              <ErrorMessage key={error.errorMessage}>
                <Icon name="Attention" size="sm" margin="0 8px 0 0" color={COLORS.RED_600} />
                {error.errorMessage}
              </ErrorMessage>
            )
        )}
    </div>
  );
};

export default SelectOrCreateDropdown;

const CreateLabel = styled.div`
  display: flex;
  align-items: center;
`;
const Dropdown = styled(Select)`
  ${DROPDOWN_STYLES};
  ${MultiSelectDropdownBaseStyles};

  .Select__control {
    margin-top: 0;
  }
  ${({ hasCreateOption }) =>
    hasCreateOption &&
    `
      .Select__option:last-of-type {
        color: ${COLORS.HALO_BLUE};
        ${FONTS.MEDIUM_2};
      }
  `}
`;
const DropdownLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  ${({ labelFont }) => labelFont};
`;
const HelpText = styled.div`
  margin-top: 8px;
  ${FONTS.REGULAR_3};
`;
const ErrorMessage = styled.div`
  display: flex;
  margin-top: 8px;
  ${FONTS.MEDIUM_3};
  color: ${COLORS.RED_600};
`;
