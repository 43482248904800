import { FEATURE_FLAGS } from "@constants";
import { authenticityToken } from "@utils/requests";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

export const DEFAULT_RETRY_COUNT = 3;

const api = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": authenticityToken()!,
  },
});

// Redirect to reauthenticate.
if (FEATURE_FLAGS.SESSION_TIMEOUT) {
  api.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
      if (error.response?.status !== 401) {
        throw error;
      }
      toast.error("You have been logged out. Please login again.");
      window.location.href = "/login";
    }
  );
}

export default api;
