import { IconButton } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { track } from "@utils/appUtils";
import { ReactNode, useEffect, useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import DropdownGroup from "./DropdownGroup";
import { ExtendedDropdownOptionOrGroup, MoreActionsOptionLabel } from "./DropdownOption";
import DROPDOWN_STYLES from "./dropdownStyles";

interface Props {
  children?: ReactNode; // Trigger component. Defaults to a "More Actions" IconButton
  options: ExtendedDropdownOptionOrGroup[];
  margin?: string | 0;
  menuHorizontalPosition?: "right" | "left";
  menuWidth?: string;
  portal?: boolean;
}

const DropdownIndicator = (props) => {
  return (
    // This is the recommended approach via the React-Select docs
    /* eslint-disable-next-line */ //@ts-ignore
    <components.DropdownIndicator {...props}>
      {props.selectProps.children ?? (
        <IconButton iconName="More Actions" size="sm" variant="ghost" tooltipWidth="100px" />
      )}
    </components.DropdownIndicator>
  );
};

// This dropdown is used when dropdown's value isn't tied to the selected option, as it is with
// SelectableDropdown. Options can have various effects that may or may not be related to the
// dropdown
const MoreActionsDropdown = ({
  children,
  options,
  margin = 0,
  menuHorizontalPosition,
  menuWidth,
  portal,
}: Props) => {
  const [portalOffsetRight, setPortalOffsetRight] = useState(0);

  useEffect(() => {
    if (!portal) return;

    const element = document.getElementById(id);

    setPortalOffsetRight(window.innerWidth - Number(element?.getBoundingClientRect().right));
  }, [portal]);

  const id = uuidv4();

  return (
    <Dropdown
      id={id}
      components={{ DropdownIndicator }}
      classNamePrefix="Select"
      options={options}
      formatOptionLabel={MoreActionsOptionLabel}
      formatGroupLabel={DropdownGroup}
      onChange={(e: ExtendedDropdownOptionOrGroup) => {
        if ("options" in e) return;
        track(SegmentEventName.Click, {
          ui_component: "Option",
          react_component: "MoreActionsDropdown",
          option: e.value,
        });
        e.onSelect!();
      }}
      margin={margin}
      menuPlacement="auto"
      menuPosition={portal ? "fixed" : "absolute"}
      menuHorizontalPosition={menuHorizontalPosition}
      menuPortalTarget={portal && document.body}
      menuWidth={menuWidth ?? "300px"}
      styles={
        portal && {
          menu: (base) => ({
            ...base,
            padding: "8px 0",
            width: menuWidth,
            ...(menuHorizontalPosition === "right" && { right: 0 }),
          }),
          menuList: (base) => ({
            ...base,
            display: "flex",
            flexDirection: "column",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            ...(menuHorizontalPosition === "right" && { right: portalOffsetRight, left: "unset" }),
          }),
          option: (base) => ({
            ...base,
            width: "unset",
            margin: "0 12px",
            cursor: "pointer",
          }),
        }
      }
      menuShouldBlockScroll={portal}
    >
      {children}
    </Dropdown>
  );
};

export default MoreActionsDropdown;

const Dropdown = styled(Select)`
  ${DROPDOWN_STYLES};
  margin: ${({ margin }) => margin};

  .Select__control {
    background-color: transparent;
    border: none;
    min-height: unset;
    &:hover {
      &:not(.Select__control--is-focused) {
        border: none;
      }
      &.Select__control--is-focused {
        border: none;
      }
    }
  }
  .Select__control--is-focused {
    /* react-select uses !important for outline */
    outline: none !important;
    box-shadow: none;
  }
  .Select__control--menu-is-open {
    min-height: 0;
    height: inherit;
  }
  .Select__value-container {
    height: 0;
    width: 0;
    padding: 0;
  }
  .Select__indicator {
    margin-right: 0;
  }
  .Select__option {
    min-width: unset;
  }
  .Select__option--is-selected {
    ${FONTS.REGULAR_2};
    color: ${COLORS.BLACK};
  }
  .Select__indicators {
    align-items: flex-start;
    height: fit-content;
  }
  .Select__menu {
    width: fit-content;
    padding: 8px 0;
    ${({ menuWidth }) =>
      menuWidth &&
      `
      width: ${menuWidth};
      white-space: normal;
    `}
    ${({ menuHorizontalPosition }) =>
      menuHorizontalPosition &&
      `
        position: absolute;
        ${menuHorizontalPosition}: 0;
  `}
  }
  .Select__control:hover:not(.Select__control--is-focused) {
    border: none;
  }
`;
