import type { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { UserOption } from "@components/library/Dropdowns/UserDropdown";
import { CompanyMember } from "@tsTypes/users/_company_member";

export const getOptionFromValue = (value: string | undefined | null): DropdownOption | null =>
  value
    ? {
        value: value,
        label: value,
      }
    : null;

export const getValueFromOption = (option: DropdownOption | null): string | undefined =>
  option?.value ?? undefined;

export const generateEmailDropdownOptions = (
  companyMembers: CompanyMember[],
  excludedEmails: string[] = [],
  onlyLicensed = false
): UserOption[] => {
  if (companyMembers.length > 0) {
    return companyMembers.flatMap((sponsorUser) => {
      // Skip if missing info
      if (
        !(sponsorUser.email && sponsorUser.first_name && sponsorUser.last_name && sponsorUser.image)
      ) {
        return [];
      }

      // Skip if user is already in the list
      if (excludedEmails.some((excludedEmail) => excludedEmail === sponsorUser.email)) {
        return [];
      }

      // Skip if only showing premium and user is not premium
      if (onlyLicensed && !sponsorUser.profile_info.license) {
        return [];
      }

      return {
        value: sponsorUser.email,
        label: sponsorUser.first_name + " " + sponsorUser.last_name,
        user: {
          name: sponsorUser.first_name + " " + sponsorUser.last_name,
          image: {
            medium: {
              url: sponsorUser.image.medium.url,
            },
          },
          id: sponsorUser.id,
          email: sponsorUser.email,
          profile_info: {
            isAdmin: sponsorUser.profile_info.is_admin,
            license: sponsorUser.profile_info.license,
          },
        },
      };
    });
  }

  return [];
};

// Returns array of dropdown options in descending order
export const getYearOptions = (top = new Date().getFullYear(), bottom = 1950): DropdownOption[] => {
  return Array.from({ length: top - bottom + 1 }, (_, i) => top - i).map((year) => ({
    label: String(year),
    value: String(year),
  }));
};
