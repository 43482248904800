import { COLORS } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  color?: string;
  margin?: string;
  children?: ReactNode;
}

const StatusCircle = ({ color, margin, children }: Props) => {
  return (
    <StyledStatusCircle color={color} margin={margin}>
      {children}
    </StyledStatusCircle>
  );
};

const StyledStatusCircle = styled.div<{ color?: string; margin?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ margin }) => margin && `margin: ${margin};`}

  width: 16px;
  height: 16px;

  border-radius: 50%;

  background-color: ${({ color }) => color || COLORS.GREEN_400};
`;

export default StatusCircle;
