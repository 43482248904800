import { ChipCheckbox, RemovableInputWrapper } from "@components/library";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";

interface Props {
  label?: string;
  options: { value: string; label: string }[];
  selectedValues: string[] | null;
  disabledValues?: string[];
  onChange: (values: string[] | null) => void;
  gap?: string;
  hideAll?: boolean;
  "data-testid"?: string;
}

const ChipsInput = ({
  label,
  options,
  selectedValues,
  disabledValues = [],
  onChange,
  gap = "16px 8px",
  hideAll = false,
  "data-testid": dataTestId,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(Number(selectedValues?.length) > 0);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    let newValues: string[];
    if (e.target.checked) {
      newValues = [...(selectedValues ?? []), value];
    } else {
      newValues = (selectedValues ?? []).filter((selectedValue) => selectedValue !== value);
    }
    onChange(newValues);
  };

  const areAllSelected = selectedValues?.length === options.length - disabledValues.length;

  const handleAllChange = () => {
    if (areAllSelected) {
      onChange([]);
    } else {
      const nonDisabledValues = options
        .map((option) => option.value)
        .filter((value) => !disabledValues.includes(value));
      onChange(nonDisabledValues);
    }
  };

  const chips = (
    <Chips gap={gap}>
      {!hideAll && <ChipCheckbox label="All" checked={areAllSelected} onChange={handleAllChange} />}
      {options.map((option) => (
        <ChipCheckbox
          key={option.value}
          label={option.label}
          checked={Boolean(selectedValues?.includes(option.value))}
          disabled={disabledValues.includes(option.value)}
          onChange={(e) => handleChange(e, option.value)}
        />
      ))}
    </Chips>
  );

  return (
    <div data-testid={dataTestId}>
      {label ? (
        <RemovableInputWrapper
          label={label}
          onRemove={() => onChange(null)}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          data-testid={`${dataTestId}-wrapper`}
        >
          {chips}
        </RemovableInputWrapper>
      ) : (
        chips
      )}
    </div>
  );
};

export default ChipsInput;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap};
`;
