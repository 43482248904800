import { MouseEventHandler, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { COLORS, FONTS } from "@constants";

const MARGIN_TOP = 110;
const MARGIN_TOP_STICKY = 20;

export interface StandardBannerProps {
  text: string;
  icon?: SVGElement;
  link?: {
    text: string;
    url: string;
    isExternal?: boolean; // Defaults to true, uses React Router if false
  };
}

const StandardBanner = ({ text, icon, link }: StandardBannerProps) => {
  const history = useHistory();
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const onScrollSticky: EventListener = () => {
    if (window.scrollY >= MARGIN_TOP - MARGIN_TOP_STICKY) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollSticky);
    return () => {
      window.removeEventListener("scroll", onScrollSticky);
    };
  });

  const handleLinkClick =
    (url: string): MouseEventHandler<HTMLSpanElement> =>
    () => {
      if (link?.isExternal) {
        window.location.href = url;
      } else {
        history.push(url);
      }
    };

  return (
    <Banner isSticky={isSticky}>
      {icon && <Icon src={icon} />}
      <Text>{text}</Text>
      {link?.text && link?.url && <Link onClick={handleLinkClick(link.url)}>{link.text}</Link>}
    </Banner>
  );
};

const Banner = styled.div`
  ${({ isSticky }) =>
    isSticky
      ? `
    position: fixed;
    margin-top: ${MARGIN_TOP_STICKY}px;
  `
      : `
    position: absolute;
    margin-top: ${MARGIN_TOP}px;
  `}
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  min-width: 60%;
  padding: 15px 20px;
  text-align: center;
  z-index: 999;
  border-radius: 4px;
  box-shadow: 0px 4px 10px -4px rgba(28, 50, 79, 0.28);
  background-color: ${COLORS.HALO_BLUE};
  color: ${COLORS.WHITE};
`;

const Icon = styled.img`
  height: 24px;
  margin-top: -2px;
  margin-right: 10px;
  fill: ${COLORS.WHITE};
`;

const Text = styled.span`
  ${FONTS.REGULAR_2}
`;

const Link = styled.span`
  ${FONTS.SEMIBOLD_2}
  text-decoration: underline;
  margin-left: 15px;
  cursor: pointer;
`;

export default StandardBanner;
