import { COLORS, FONTS } from "@constants";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  content: string | ReactNode;
  tooltipWidth?: string;
  contentWidth?: string;
  position?: "top" | "bottom" | "left" | "right";
  arrowOffsetTop?: string | 0;
  remainOnHover?: boolean;
  shouldWrap?: boolean;
  isActive?: boolean;
  shouldFlex?: boolean;
  asPortal?: boolean;
  "data-testid"?: string;
}

const Tooltip = ({
  children,
  content,
  tooltipWidth,
  contentWidth,
  position = "top",
  arrowOffsetTop,
  remainOnHover = true,
  shouldWrap = true,
  isActive = true,
  shouldFlex = false,
  asPortal = false,
  "data-testid": dataTestId,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const childrenRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const toggleShow = (newState) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setShowTooltip(newState), 100);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const childrenHeight = childrenRef.current?.clientHeight ?? 0;

  if (asPortal) {
    const childrenWidth = childrenRef.current?.clientWidth ?? 0;

    const clientRect = childrenRef.current?.getBoundingClientRect();

    // position === 'top'
    let tooltipVerticalOffset =
      window.innerHeight - Number(clientRect?.top) - document.documentElement.scrollTop + 12;
    let tooltipHorizontalOffset =
      Number(clientRect?.left) + document.documentElement.scrollLeft + childrenWidth / 2;

    if (position === "bottom") {
      tooltipVerticalOffset =
        Number(clientRect?.top) + document.documentElement.scrollTop + childrenHeight + 12;
    } else if (position === "left" || position === "right") {
      tooltipVerticalOffset =
        Number(clientRect?.top) + document.documentElement.scrollTop + childrenHeight / 2;

      if (position === "left") {
        tooltipHorizontalOffset =
          window.innerWidth - Number(clientRect?.left) + document.documentElement.scrollLeft;
      } else {
        tooltipHorizontalOffset =
          Number(clientRect?.right) - document.documentElement.scrollLeft + 12;
      }
    }

    return (
      <>
        <ChildrenContainer
          onMouseEnter={() => toggleShow(true)}
          onMouseLeave={() => toggleShow(false)}
          ref={childrenRef}
          shouldFlex={shouldFlex}
          contentWidth={contentWidth}
          asPortal={asPortal}
        >
          {children}
        </ChildrenContainer>
        {showTooltip &&
          createPortal(
            <PortaledTooltip
              showTooltip={isActive && showTooltip}
              width={tooltipWidth}
              position={position}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onMouseEnter={() => remainOnHover && toggleShow(true)}
              onMouseLeave={() => toggleShow(false)}
              data-testid={dataTestId}
              tooltipVerticalOffset={tooltipVerticalOffset}
              tooltipHorizontalOffset={tooltipHorizontalOffset}
            >
              <ContentContainer shouldWrap={shouldWrap}>{content}</ContentContainer>
              <Arrow position={position} arrowOffsetTop={arrowOffsetTop} asPortal={asPortal} />
            </PortaledTooltip>,
            document.body
          )}
      </>
    );
  }

  const childrenVerticalCenter = `${childrenHeight / 2 - 8}px`;
  const top = arrowOffsetTop ?? childrenVerticalCenter;

  return (
    <Container contentWidth={contentWidth}>
      <ChildrenContainer
        onMouseEnter={() => toggleShow(true)}
        onMouseLeave={() => toggleShow(false)}
        ref={childrenRef}
        shouldFlex={shouldFlex}
      >
        {children}
      </ChildrenContainer>
      {showTooltip && (
        <NonPortaledTooltip
          showTooltip={isActive && showTooltip}
          width={tooltipWidth}
          position={position}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseEnter={() => remainOnHover && toggleShow(true)}
          onMouseLeave={() => toggleShow(false)}
          data-testid={dataTestId}
        >
          <ContentContainer shouldWrap={shouldWrap}>{content}</ContentContainer>
          <Arrow position={position} arrowOffsetTop={top} />
        </NonPortaledTooltip>
      )}
    </Container>
  );
};

export default Tooltip;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  ${({ contentWidth }) => `width: ${contentWidth}`}
`;
const ChildrenContainer = styled.div`
  ${({ shouldFlex }) => shouldFlex && "display: flex;"}
  ${({ contentWidth, asPortal }) =>
    asPortal
      ? `
        height: fit-content;
        width: ${contentWidth ?? "fit-content"};
      `
      : `
        width: 100%;
      `}
`;
const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  ${({ width }) => width && `width: ${width};`}
  ${({ shouldWrap }) => !shouldWrap && `white-space: nowrap;`}
  border-radius: 6px;
  padding: 12px;
  text-align: left;
  ${FONTS.REGULAR_3};
  cursor: auto;
`;
const PortaledTooltip = styled(StyledTooltip)`
  ${({ position, tooltipVerticalOffset, tooltipHorizontalOffset }) => {
    switch (position) {
      case "top":
        return `
          bottom: ${tooltipVerticalOffset}px;
          left: ${tooltipHorizontalOffset}px;
          transform: translate(-50%, 0);        
        `;
      case "bottom":
        return `
          top: ${tooltipVerticalOffset}px;
          left: ${tooltipHorizontalOffset}px;
          transform: translate(-50%, 0);        
        `;
      case "left":
        return `
          top: ${tooltipVerticalOffset}px;
          right: ${tooltipHorizontalOffset}px;
          transform: translate(0, -50%);
        `;
      case "right":
        return `
        top: ${tooltipVerticalOffset}px;
          left: ${tooltipHorizontalOffset}px;
          transform: translate(0, -50%);        
          `;
    }
  }}
  z-index: 510;
`;
const NonPortaledTooltip = styled(StyledTooltip)`
  ${({ position }) => {
    switch (position) {
      case "top":
        return `
          bottom: calc(100% + 12px);
        `;
      case "bottom":
        return `
          top: calc(100% + 12px);
        `;
      case "left":
        return `
          right: calc(100% + 12px);
        `;
      case "right":
        return `
          left: calc(100% + 12px);
        `;
    }
  }}
  z-index: 1;
`;
const ContentContainer = styled.div`
  width: 100%;
  white-space: ${({ shouldWrap }) => (shouldWrap ? "normal" : "no-wrap")};
`;
const Arrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  ${({ position, arrowOffsetTop, isPortal }) => {
    switch (position) {
      case "top":
        return `
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid ${COLORS.BLACK};
          bottom: -8px;
        `;
      case "bottom":
        return `
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid ${COLORS.BLACK};
          top: -8px;
        `;
      case "left":
        return `
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid ${COLORS.BLACK};
          right: -8px;
          ${
            !isPortal || arrowOffsetTop
              ? `top: ${arrowOffsetTop}`
              : `
                  top: 50%;
                  transform: translateY(-50%);
                `
          }
        `;
      case "right":
        return `
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid ${COLORS.BLACK};
          left: -8px;
          ${
            !isPortal || arrowOffsetTop
              ? `top: ${arrowOffsetTop}`
              : `
                  top: 50%;
                  transform: translateY(-50%);
                `
          }        
        `;
    }
  }}
`;
