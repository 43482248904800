import { COLORS } from "@constants";
import { RootState } from "@redux/store";
import { SponsorLicenseType } from "@tsTypes/sponsorProfiles";
import { t } from "@utils/i18n";
import { useSelector } from "react-redux";
import Tag from "../Tag";
import { LicenseTagTooltip } from "./LicenseTagTooltip";

interface Props {
  type: SponsorLicenseType;
}

export function LicenseTag({ type }: Props) {
  const subscription = useSelector((state: RootState) => state.company.subscription);

  if (subscription?.plan !== "seats_based") return null;

  return (
    <LicenseTagTooltip
      width=""
      content={t([
        "components.license_tag",
        type === SponsorLicenseType.PREMIUM ? "premium_tooltip" : "standard_tooltip",
      ])}
    >
      <Tag
        iconName={type === SponsorLicenseType.PREMIUM ? "Star" : "Diamond"}
        content={type === SponsorLicenseType.PREMIUM ? "Premium" : "Standard"}
        size="xs"
        backgroundColor={COLORS.PINK_100}
        color={COLORS.PINK_800}
        data-testid={`${type}-tag`}
      />
    </LicenseTagTooltip>
  );
}
