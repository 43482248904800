import { COLORS, FONTS } from "@constants";
import { ReactNode } from "react";
import ReactSwitch from "react-switch";
import styled from "styled-components";

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => any;
  size?: "sm" | "lg";
  labelLeft?: string;
  labelRight?: string | ReactNode;
  disabled?: boolean;
  margin?: string | 0;
  disabledColor?: string;
}

const Switch = ({
  checked,
  onChange,
  size = "sm",
  labelLeft,
  labelRight,
  disabled,
  disabledColor = COLORS.BLACK,
  margin,
}: Props) => {
  return (
    <Container margin={margin} hasTwoLabels={labelLeft && labelRight}>
      {labelLeft}
      <SwitchContainer data-testid="switch">
        <ReactSwitch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          onColor={COLORS.HALO_BLUE}
          offColor={disabledColor}
          uncheckedIcon={false}
          checkedIcon={false}
          activeBoxShadow={""}
          handleDiameter={size === "sm" ? 14 : 21}
          height={size === "sm" ? 18 : 24}
          width={size === "sm" ? 34 : 42}
        />
      </SwitchContainer>
      {labelRight}
    </Container>
  );
};

export default Switch;

const Container = styled.div`
  ${FONTS.MEDIUM_2}
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ hasTwoLabels }) => (hasTwoLabels ? "6px" : "11px")};
  height: fit-content;
  ${({ margin }) => margin && `margin: ${margin}`};
`;
const SwitchContainer = styled.span`
  display: flex;
`;
