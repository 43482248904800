import {
  Button,
  Checkbox,
  Icon,
  InputTooltip,
  TextButton,
  TextInput,
  Tooltip,
} from "@components/library";
import { Label, LabelText, Optional } from "@components/library/Inputs/TextInput";
import { COLORS, FONTS } from "@constants";
import { startsWithVowel } from "@utils/textUtils";
import { ChangeEvent, ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

interface Props {
  values: string[];
  onChange: (values: string[]) => void;
  label?: string;
  labelFont?: string;
  sublabel?: ReactNode;
  placeholder?: string;
  itemName?: string; // e.g. "requirement," "out-of-scope item," etc.
  isOptional?: boolean;
  asTextArea?: boolean;
  characterLimit?: number;
  errors?: { hasError: boolean; errorMessage: string }[];
  resize?: string;
  startingHeight?: string;
  tooltipContent?: string | ReactNode;
  tooltipWidth?: string;
  tooltipOffsetTop?: string;
  tooltipArrowOffsetTop?: string;
  checkbox?: {
    label: string;
    labelFont: string;
    isValueChecked: (valueIndex: number) => boolean;
    onChange: (changedValueIndex: number, isChecked: boolean) => void;
    tooltipContent?: string | ReactNode;
    tooltipWidth?: string;
  };
  "data-testid"?: string;
}

const MultiTextInput = ({
  values,
  onChange,
  label,
  labelFont,
  sublabel,
  placeholder,
  itemName,
  isOptional = false,
  asTextArea = true,
  characterLimit,
  errors,
  resize = "vertical",
  startingHeight,
  tooltipContent,
  tooltipWidth,
  tooltipOffsetTop,
  tooltipArrowOffsetTop,
  checkbox,
  "data-testid": dataTestId,
}: Props) => {
  const inputKeysRef = useRef<string[]>(values.map(() => uuidv4()));
  const didAddOrRemoveRef = useRef<boolean>(false);
  const hiddenInputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (didAddOrRemoveRef.current) {
      if (values.length === 0) {
        // This is needed because without a focusable element (e.g. input), the tooltip wrapper can't
        // be blurred, and the tooltip will persist.  So, we add a non-visible input that can take the
        // focus when the last "real" input is removed, then blur it to hide the tooltip
        hiddenInputRef.current?.focus();
        hiddenInputRef.current?.blur();
      } else {
        const lastInputId = inputKeysRef.current[inputKeysRef.current.length - 1];
        const lastInput = document.getElementById(lastInputId);
        lastInput?.focus();
      }

      didAddOrRemoveRef.current = false;
    }
  }, [values]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => {
    const newValues = [...values];
    newValues.splice(i, 1, e.target.value);
    onChange(newValues);
  };

  const handleAdd = () => {
    const uniqueId = uuidv4();
    inputKeysRef.current.push(uniqueId);

    onChange([...values, ""]);
    didAddOrRemoveRef.current = true;
  };

  const handleRemove = (e: ChangeEvent<HTMLButtonElement>, i: number) => {
    e.preventDefault();
    const currentKey = inputKeysRef.current[i];
    inputKeysRef.current = inputKeysRef.current.filter((key) => key !== currentKey);

    const newValues = [...values];
    newValues.splice(i, 1);
    onChange(newValues);
    didAddOrRemoveRef.current = true;
  };

  const hasAnyError =
    errors?.map((error) => error.hasError).includes(true) ||
    (characterLimit && values.some((entry) => entry.length > characterLimit));

  const addButtonText =
    values.length === 0
      ? `Add${itemName && startsWithVowel(itemName) ? ` an ${itemName}` : ` a ${itemName}`}`
      : `Add another${itemName && ` ${itemName}`}`;

  const inputs = (
    <Inputs>
      <HiddenInput ref={hiddenInputRef} tabIndex="-1" />
      {values.map((value, i) => {
        const inputComponent = (
          <TextInput
            id={inputKeysRef.current[i]}
            value={value}
            onChange={(e) => handleInputChange(e, i)}
            textarea={asTextArea}
            placeholder={placeholder}
            characterLimit={characterLimit}
            errors={errors}
            resize={resize}
            startingHeight={startingHeight}
            data-testid="input"
          />
        );
        return (
          <div key={inputKeysRef.current[i]}>
            <InputContainer>
              {tooltipContent ? (
                <InputTooltip
                  content={tooltipContent}
                  width={tooltipWidth}
                  tooltipOffsetTop={tooltipOffsetTop}
                  arrowOffsetTop={tooltipArrowOffsetTop}
                >
                  {inputComponent}
                </InputTooltip>
              ) : (
                inputComponent
              )}
              <RemoveButtonContainer>
                <TextButton
                  text="Remove"
                  iconName="Delete"
                  iconPosition="left"
                  color={COLORS.RED}
                  onClick={(e) => handleRemove(e, i)}
                  data-testid="remove-button"
                />
              </RemoveButtonContainer>
            </InputContainer>
            {checkbox && (
              <CheckboxContainer>
                <Checkbox
                  size="sm"
                  label={checkbox.label}
                  labelFont={checkbox.labelFont}
                  isChecked={checkbox.isValueChecked(i)}
                  onChange={(e) => checkbox.onChange(i, e.target.checked)}
                />
                {Boolean(checkbox.tooltipContent) && (
                  <Tooltip
                    content={checkbox.tooltipContent}
                    position="right"
                    tooltipWidth={checkbox.tooltipWidth}
                    arrowOffsetTop="calc(50% - 8px)"
                  >
                    <Icon name="Info" size="xs" margin="0 0 0 6px" color={COLORS.NEUTRAL_400} />
                  </Tooltip>
                )}
              </CheckboxContainer>
            )}
          </div>
        );
      })}
    </Inputs>
  );

  return (
    <Container data-testid={dataTestId}>
      {label ? (
        <Label labelFont={labelFont}>
          <LabelText hasError={hasAnyError}>
            {label}
            {isOptional && <Optional>Optional</Optional>}
          </LabelText>
          {sublabel && <SublabelText hasItems={values.length}>{sublabel}</SublabelText>}
          {inputs}
        </Label>
      ) : (
        Boolean(values.length) && inputs
      )}
      <Button
        variant="secondary"
        size="sm"
        iconName="Add"
        iconPosition="left"
        onClick={handleAdd}
        margin="0 auto 0 0"
        data-testid="add-button"
      >
        {addButtonText}
      </Button>
    </Container>
  );
};

export default MultiTextInput;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;
const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
`;
const HiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;
const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const RemoveButtonContainer = styled.div`
  position: absolute;
  bottom: -4px;
  right: 0;
`;
const SublabelText = styled(LabelText)`
  ${FONTS.REGULAR_2}
  margin-bottom: ${({ hasItems }) => (hasItems ? "24px" : "0")};
`;
