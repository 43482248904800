import { COLORS, STYLES } from "@constants";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { track } from "@utils/appUtils";
import { ReactElement, cloneElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  // The element(s) over which the tooltip should show when hovered
  children: ReactElement | ReactElement[];
  tags: ReactElement[];
}

const TooltipWrapper = ({ children, tags }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [displayedTags, setDisplayedTags] = useState<ReactElement[]>([]);

  // Make sure the proper tag theme is used
  useEffect(() => {
    const updatedTags: ReactElement[] = [];

    for (const tag of tags) {
      const updatedTag = cloneElement(tag, {
        theme: "neutral",
      });

      updatedTags.push(updatedTag);

      setDisplayedTags(updatedTags);
    }
  }, [tags]);

  // This is used to prevent the tooltip from flashing if a user passes over the child element(s)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const toggleShow = (newState) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setIsHovered(newState), 100);
  };

  const size = tags[0]?.props?.size;

  return (
    <Wrapper
      onMouseEnter={() => {
        track(SegmentEventName.View, {
          ui_component: "TagTooltip",
        });
        toggleShow(true);
      }}
      onMouseLeave={() => toggleShow(false)}
    >
      {children}
      <Container isHovered={isHovered} size={size} columns={Math.ceil(displayedTags.length / 10)}>
        {displayedTags.map((tag) => (
          <TagContainer key={tag.key} size={size}>
            {tag}
          </TagContainer>
        ))}
      </Container>
    </Wrapper>
  );
};

export default TooltipWrapper;

const Wrapper = styled.div`
  position: relative;
`;
const Container = styled.div`
  position: absolute;
  display: ${({ isHovered }) => (isHovered ? "grid" : "none")};
  grid-template-columns: repeat(${({ columns }) => columns}, auto);
  gap: 0 20px;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 22px 18px;
  padding: ${({ size }) => (size === "xs" ? "5px 11px 9px" : "10px 22px 18px")};
  border-radius: 8px;
  box-shadow: ${STYLES.SHADOW_B};
  background-color: ${COLORS.WHITE};
  z-index: 1;
`;
const TagContainer = styled.div`
  margin-top: ${({ size }) => (size === "xs" ? "0" : "8px")};
`;
