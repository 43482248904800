import { COLORS, FONTS, STYLES } from "@constants";
import { MessageAttachmentAsJson } from "@requests/messages";
import { formatBytes, formatMIMEContentType } from "@utils/textUtils";
import { MouseEvent } from "react";
import styled from "styled-components";

import { ProposalRequestRequirementAttachmentAsJson } from "@requests/proposalRequestRequirement";
import IconButton from "../Buttons/IconButton";

export type AttachmentForDisplay = Partial<
  MessageAttachmentAsJson | ProposalRequestRequirementAttachmentAsJson
>;

interface Props {
  attachment: AttachmentForDisplay;
  isInbox?: boolean;
  size?: "sm" | "md" | "lg";
  uploadPercentage?: number; // integer within [0, 100]; undefined means the attachment is sent
  onRemove?: () => void;
  onClick?: () => void;
}

const MessageAttachment = ({
  attachment,
  isInbox,
  size = "md",
  uploadPercentage,
  onRemove,
  onClick,
}: Props) => {
  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onRemove?.();
  };

  const isForUpload = typeof uploadPercentage !== "undefined";

  const { filename, size_in_bytes, mime_content_type, thumbnail_url } = attachment;

  const name = filename;
  const fileSize = formatBytes(size_in_bytes);
  const type = formatMIMEContentType(mime_content_type);
  const isImage = mime_content_type?.startsWith("image/");

  return (
    <Container
      isInbox={isInbox}
      size={size}
      isForUpload={isForUpload}
      isImage={isImage}
      onClick={onClick}
      data-testid="message-attachment"
    >
      <Preview>
        {(() => {
          // Display thumbnail if thumbnail exists
          if (thumbnail_url) return <Thumbnail src={thumbnail_url} />;
          // Otherwise, display loading bar (max out at 95% until thumbnail is done loading too)
          return <ProgressBar percentage={Math.min(uploadPercentage ?? 0, 95)} />;
        })()}
      </Preview>
      <div>
        <Name>{name}</Name>
        <Info>
          {type} &bull; {fileSize}
        </Info>
      </div>
      {isForUpload && (
        <RemoveButtonContainer>
          <IconButton
            iconName="X"
            hideTooltip
            variant="fifth"
            size="xs"
            onClick={handleRemove}
            data-testid="remove-button"
          />
        </RemoveButtonContainer>
      )}
    </Container>
  );
};

const Preview = styled.div`
  display: flex;
  align-items: center;
  flex: none;
  width: 60px;
  height: 60px;
  background: ${COLORS.NEUTRAL_300};
  border-radius: 4px;
  position: relative;
  overflow-x: hidden;
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  display: block;
  background: ${COLORS.HALO_BLUE};
  height: 3px;
  transition: width 0.1s linear;
  width: ${({ percentage }) => percentage}%;
`;

const Thumbnail = styled.img`
  background: ${COLORS.WHITE};
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Name = styled.div`
  ${FONTS.MEDIUM_3}
  ${STYLES.ONE_LINE}
  max-width: 188px;
`;

const Info = styled.div`
  ${FONTS.REGULAR_3}
  ${STYLES.ONE_LINE}
  color: ${COLORS.NEUTRAL_600};
`;

const RemoveButtonContainer = styled.div`
  &&& {
    position: absolute;
    top: -10px;
    right: -10px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 280px;
  height: 76px;
  display: flex;
  gap: 12px;
  padding: 0 12px 0 8px;
  align-items: center;
  border-radius: 8px;
  background: ${({ isInbox, isForUpload }) =>
    !isInbox && isForUpload ? COLORS.WHITE : COLORS.NEUTRAL_100};

  &:hover {
    cursor: pointer;

    & ${Name} {
      text-decoration: underline;
    }
  }

  ${({ isForUpload, isImage, size }) =>
    !isForUpload &&
    isImage &&
    `
    height: 255px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px; 

    & ${Preview} {
      width: 264px;
      height: 187px;
    }

    & ${Name} {
      max-width: 264px;
    }
  `}

  ${({ size }) =>
    size === "sm" &&
    `
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    height: 28px;
    padding: 2px;
    border-radius: 6px;
    width: initial;
    background: ${COLORS.NEUTRAL_100};

    & ${Preview} {
      width: 24px;
      height: 24px;
    }

    & ${Info} {
      display: none;
    }

    & ${Name} {
      max-width: unset;
    }
  `}
`;

export default MessageAttachment;
