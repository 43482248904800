import type { MessengerData } from "@redux/reducers/messengerReducer";
import { ProposalyType } from "@tsTypes/proposaly";
import { payloadActionGenerator } from "@utils/redux";
import { batch } from "react-redux";

import {
  getMessageThreadByRecipients,
  getMessageThreadByUserProposal,
} from "src/requests/messages";

export const SET_MESSENGER_DATA = "SET_MESSENGER_DATA";
export const CLEAR_MESSENGER_STATE = "CLEAR_MESSENGER_STATE";
export const SET_MESSENGER_THREAD = "SET_MESSENGER_THREAD";
export const SET_HAS_UNREAD_MESSAGE = "SET_HAS_UNREAD_MESSAGE";
export const SET_IS_MESSENGER_OPEN = "SET_IS_MESSENGER_OPEN";
export const SET_IS_MESSENGER_MINIMIZED = "SET_IS_MESSENGER_MINIMIZED";
export const SET_IS_MESSENGER_EXPANDED = "SET_IS_MESSENGER_EXPANDED";
export const IS_MESSENGER_THREAD_LOADING = "IS_MESSENGER_THREAD_LOADING";
export const SET_MESSENGER_OFFSET_RIGHT = "SET_MESSENGER_OFFSET_RIGHT";

export const setMessengerData = payloadActionGenerator(SET_MESSENGER_DATA);
export const clearMessengerState = payloadActionGenerator(CLEAR_MESSENGER_STATE);
export const setMessengerThread = payloadActionGenerator(SET_MESSENGER_THREAD);
export const setHasUnreadMessage = payloadActionGenerator(SET_HAS_UNREAD_MESSAGE);
export const setIsMessengerOpen = payloadActionGenerator(SET_IS_MESSENGER_OPEN);
export const setIsMessengerMinimized = payloadActionGenerator(SET_IS_MESSENGER_MINIMIZED);
export const setIsMessengerExpanded = payloadActionGenerator(SET_IS_MESSENGER_EXPANDED);
export const setIsMessengerThreadLoading = payloadActionGenerator(IS_MESSENGER_THREAD_LOADING);
export const setMessengerOffsetRight = payloadActionGenerator(SET_MESSENGER_OFFSET_RIGHT);

export const fetchCurrentThreadByProposal = (
  userId: number,
  proposalyType: ProposalyType,
  proposalyId: number
) => {
  return async (dispatch) => {
    dispatch(setIsMessengerThreadLoading(true));
    const response = await getMessageThreadByUserProposal({ userId, proposalyType, proposalyId });

    batch(() => {
      dispatch(setMessengerThread(response?.message_thread));
      dispatch(setHasUnreadMessage(response?.message_thread.unread_for_current_user ?? false));
      dispatch(setIsMessengerThreadLoading(false));
    });

    return response?.message_thread;
  };
};

export const fetchCurrentThreadByParticipants = (
  scientistUserId: number,
  recipientUserId: number
) => {
  return async (dispatch) => {
    dispatch(setIsMessengerThreadLoading(true));
    const response = await getMessageThreadByRecipients({
      scientistUserId,
      recipientUserId,
    });

    batch(() => {
      dispatch(setMessengerThread(response?.message_thread));
      dispatch(setHasUnreadMessage(response?.message_thread.unread_for_current_user ?? false));
      dispatch(setIsMessengerThreadLoading(false));
    });

    return response?.message_thread;
  };
};

export const setMessengerState = (messengerData: MessengerData) => {
  return (dispatch) => {
    batch(() => {
      dispatch(setMessengerData(messengerData));
      dispatch(setIsMessengerOpen(true));
      dispatch(setIsMessengerMinimized(false));
    });
  };
};
