import { Icon, TextButton } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { LegacyRef, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  label: ReactNode;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  onOpen?: () => void;
  onRemove: () => void;
  errors?: { hasError: boolean; errorMessage: string }[];
  "data-testid"?: string;
}

const RemovableInputWrapper = ({
  children,
  label,
  isExpanded,
  setIsExpanded,
  onOpen,
  onRemove,
  errors,
  "data-testid": dataTestId,
}: Props) => {
  const [isInitiallyOpen] = useState(isExpanded);

  const inputRef = useRef<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement | null>(null);
  const isExpandedRef = useRef(isInitiallyOpen);

  useEffect(() => {
    if (!isExpandedRef.current && isExpanded) {
      const textInput = inputRef.current?.querySelector('input[type="text"], textarea');

      if (textInput) {
        inputRef.current = textInput as HTMLInputElement | HTMLTextAreaElement;
        inputRef.current.focus();
      }
    }

    isExpandedRef.current = isExpanded;
  }, [isExpanded]);

  const hasAnyError = errors?.map((error) => error.hasError).includes(true);
  const errorIcon = <Icon name="Attention" size="sm" margin="0 8px 0 0" color={COLORS.RED_600} />;

  return (
    <Container data-testid={dataTestId}>
      <Top hasAnyError={hasAnyError}>
        {label}
        {isExpanded ? (
          <TextButton
            text="Remove"
            color={COLORS.RED}
            iconName="Delete"
            iconPosition="left"
            onClick={() => {
              onRemove();
              setIsExpanded(false);
            }}
            data-testid="remove-button"
          />
        ) : (
          <TextButton
            text="Add"
            iconName="Add"
            onClick={() => {
              onOpen?.();
              setIsExpanded(true);
            }}
            iconPosition="left"
            data-testid="add-button"
          />
        )}
      </Top>
      {isExpanded && (
        <>
          <div ref={inputRef as LegacyRef<HTMLDivElement>}>{children}</div>
          {hasAnyError && (
            <ErrorsContainer>
              {errors?.map(
                (error) =>
                  error.hasError && (
                    <ErrorRow key={error.errorMessage}>
                      {errorIcon}
                      {error.errorMessage}
                    </ErrorRow>
                  )
              )}
            </ErrorsContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default RemovableInputWrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  padding: 16px;
  background: ${COLORS.NEUTRAL_50};
  border-radius: 8px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${FONTS.SEMIBOLD_1}
  color: ${({ hasAnyError }) => (hasAnyError ? COLORS.RED_600 : COLORS.BLACK)};
`;
const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ErrorRow = styled.div`
  display: flex;
  ${FONTS.MEDIUM_3};
  color: ${COLORS.RED_600};
`;
