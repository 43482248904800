import { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { getCompanyNamesForOnboarding } from "@requests/companies";
import { getOptionFromValue } from "@utils/dropdownUtils";
import { useEffect, useState } from "react";

function useCompanyOptions(): [DropdownOption[], (e, { value }) => void] {
  const [companyOptions, setCompanyOptions] = useState<DropdownOption[]>([]);

  const addCompanyOption = (e, { value }) => {
    const newOption = getOptionFromValue(value);
    if (newOption) setCompanyOptions([newOption, ...companyOptions]);
  };

  useEffect(() => {
    (async () => {
      const { companies } = await getCompanyNamesForOnboarding();

      setCompanyOptions(companies.map(getOptionFromValue) as DropdownOption[]);
    })();
  }, []);

  return [companyOptions, addCompanyOption];
}

export default useCompanyOptions;
