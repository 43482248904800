import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  title?: string | ReactNode | null;
  subtitle?: string | ReactNode | null;
  children: ReactNode;
}

function BlueBox({ title, subtitle, children }: Props) {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {children}
    </Container>
  );
}

export default BlueBox;

const Container = styled.div`
  padding: 64px;
  background-color: ${COLORS.BLUE_LIGHT_100};
  border-radius: 8px;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    padding: 24px;
  }
`;

const Title = styled.div`
  ${FONTS.MEDIUM_1}
  color: ${COLORS.NEUTRAL_500};
  margin-bottom: 16px;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    text-align: center;
  }
`;
const Subtitle = styled.div`
  ${FONTS.HEADING_3_REGULAR}
  color: ${COLORS.BLACK};
  margin-bottom: 32px;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    text-align: center;
  }
`;
