import { TextButton } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { ReactNode, useEffect, useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { DropdownOption, ExtendedDropdownOption, OptionLabel } from "./DropdownOption";
import DROPDOWN_STYLES from "./dropdownStyles";

interface Props {
  children?: ReactNode; // Trigger component. Defaults to a TextButton with the value label
  options: readonly ExtendedDropdownOption[];
  value: DropdownOption;
  onChange: (...args: any[]) => any;
  isSearchable?: boolean;
  menuWidth?: string;
  maxMenuHeight?: string;
  menuHorizontalPosition?: "right" | "left";
  // Portal the menu with width if using within a scrollable overflow
  isPortal?: boolean;
  portalMenuWidth?: string;
  isDisabled?: boolean;
}

const DropdownIndicator = (props) => {
  const { children, value } = props.selectProps;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DropdownIndicatorComponent {...props}>
      {children ?? <TextButton text={value.label} color={COLORS.BLACK} />}
    </DropdownIndicatorComponent>
  );
};

/**
 * This dropdown is used when a selected option becomes the value of the dropdown. Often times, the
 * display will directly reflect the selected option. A typical use case would be a "sort by" menu
 *
 * menuWidth: When specified, the component will not bleed through the right edge of the screen.
 */
const SelectableDropdown = ({
  children,
  options,
  value,
  onChange,
  isSearchable = false,
  menuWidth,
  maxMenuHeight,
  menuHorizontalPosition,
  isPortal,
  portalMenuWidth = "300px",
  isDisabled = false,
}: Props) => {
  const [portalOffsetRight, setPortalOffsetRight] = useState(0);

  useEffect(() => {
    if (!isPortal) return;

    const element = document.getElementById(id);

    setPortalOffsetRight(window.innerWidth - Number(element?.getBoundingClientRect().right));
  }, [isPortal]);

  const id = uuidv4();

  return (
    <Dropdown
      id={id}
      classNamePrefix="Select"
      components={{ DropdownIndicator }}
      options={options}
      formatOptionLabel={OptionLabel}
      value={value}
      onChange={(e: ExtendedDropdownOption) => onChange(e)}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      menuWidth={menuWidth}
      maxMenuHeight={maxMenuHeight}
      menuHorizontalPosition={menuHorizontalPosition}
      menuPosition={isPortal ? "fixed" : "absolute"}
      menuPortalTarget={isPortal && document.body}
      styles={
        isPortal && {
          menu: (base) => ({
            ...base,
            padding: "8px 0",
            width: menuWidth,
            ...(menuHorizontalPosition === "right" && { right: 0 }),
          }),
          menuList: (base) => ({
            ...base,
            display: "flex",
            flexDirection: "column",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            width: portalMenuWidth,
            ...(menuHorizontalPosition === "right" && { right: portalOffsetRight, left: "unset" }),
          }),
          option: (base, { isSelected }) => ({
            ...base,
            width: "unset",
            margin: "0 12px",
            cursor: "pointer",
            ...(isSelected && { backgroundColor: COLORS.BLUE_LIGHT_400, color: COLORS.HALO_BLUE }),
          }),
        }
      }
      menuShouldBlockScroll={isPortal}
    >
      {children}
    </Dropdown>
  );
};

export default SelectableDropdown;

const Dropdown = styled(Select)`
  ${DROPDOWN_STYLES};

  ${({ menuWidth }) =>
    menuWidth &&
    `
      .Select__menu {
        white-space: normal;
        width: ${menuWidth};

        position: absolute;
        right: 0;
      }
  `}
  .Select__control {
    padding: 0 2px 0 4px;
    min-height: unset;
    border: none;
    &:hover {
      &:not(.Select__control--is-focused) {
        border: none;
      }
      &.Select__control--is-focused {
        border: none;
      }
    }
  }
  .Select__control--is-disabled {
    ${({ isDisabled }) => isDisabled && "background-color: transparent;"}
  }
  .Select__placeholder {
    color: ${COLORS.BLACK};
    ${FONTS.MEDIUM_2};
  }
  .Select__value-container {
    height: 0;
    width: 0;
    padding: 0;
  }
  .Select__indicator {
    margin: 0px;
  }
  .Select__option {
    min-width: unset;
  }
  .Select__menu {
    min-width: fit-content;
    ${({ menuHorizontalPosition }) =>
      menuHorizontalPosition &&
      `
        position: absolute;
        ${menuHorizontalPosition}: 0;
    `}
  }
  .Select__menu-list {
    max-height: ${({ maxMenuHeight }) => maxMenuHeight};
  }
  .Select__control:hover:not(.Select__control--is-focused) {
    border: none;
  }
`;
const DropdownIndicatorComponent = styled(components.DropdownIndicator)`
  ${({ isDisabled }) => isDisabled && "opacity: 0.6;"}
`;
