import { COLORS, FONTS } from "@constants";
import styled from "styled-components";
import { Checkbox, CheckboxText } from "../MultiSelectDropdown";

const KeywordsDropdownOption = ({
  innerRef,
  innerProps,
  isSelected,
  label,
  data: { __isNew__ },
}) => {
  return (
    // eslint-disable-next-line
    <CheckboxLabel ref={innerRef} {...innerProps} isSelected={isSelected} isNew={__isNew__}>
      {!__isNew__ && (
        <Checkbox isSelected={isSelected}>
          {isSelected && (
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7851 3.31402C11.9568 3.47146 11.9684 3.73832 11.811 3.91007L5.62349 10.6601C5.54573 10.7449 5.43671 10.7943 5.32166 10.7968C5.20662 10.7993 5.09555 10.7547 5.01419 10.6733L2.20169 7.86081C2.03694 7.69606 2.03694 7.42894 2.20169 7.26419C2.36644 7.09944 2.63356 7.09944 2.79831 7.26419L5.29925 9.76513L11.189 3.33993C11.3465 3.16818 11.6133 3.15658 11.7851 3.31402Z"
                fill="white"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </Checkbox>
      )}
      <CheckboxText>{label}</CheckboxText>
    </CheckboxLabel>
  );
};

export default KeywordsDropdownOption;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  ${({ isSelected }) => (isSelected ? FONTS.MEDIUM_2 : FONTS.REGULAR_2)};
  color: ${({ isSelected }) => (isSelected ? COLORS.HALO_BLUE : COLORS.BLACK)};
  margin: 0;
  padding: 10px 14px;
  border-radius: 4px;

  ${({ isNew }) =>
    isNew
      ? `padding: 6px 14px 0;`
      : `
        cursor: pointer;
        &:hover {
          background-color: ${COLORS.NEUTRAL_100};
        }
  `}
`;
