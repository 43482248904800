import { COLORS, STYLES, WINDOW_DIMENSIONS } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children?: ReactNode;
  minHeight?: string;
  showHoverBorder?: boolean;
  padding?: string | 0;
  onClick?: (...args: any[]) => any;
  onMouseEnter?: (...args: any[]) => any;
  onMouseLeave?: (...args: any[]) => any;
  "data-testid"?: string;
}

const Card = ({
  children,
  minHeight,
  showHoverBorder = true,
  padding,
  onClick,
  onMouseEnter,
  onMouseLeave,
  "data-testid": dataTestId,
}: Props) => {
  return (
    <Container
      minHeight={minHeight}
      showHoverBorder={showHoverBorder}
      padding={padding}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={dataTestId}
    >
      {children}
    </Container>
  );
};

export default Card;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  outline: 1px solid ${COLORS.NEUTRAL_250};
  border: none;
  background-color: ${COLORS.WHITE};
  text-align: left;
  border-radius: ${({ showHoverBorder }) => (showHoverBorder ? "4px" : "12px 12px 4px 4px")};
  box-shadow: ${STYLES.SHADOW_CARD};
  cursor: pointer;

  ${({ showHoverBorder }) => showHoverBorder && "border-top: 4px solid transparent;"}
  padding: ${({ padding }) => padding || "16px"};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}

  &:hover {
    background-color: ${COLORS.NEUTRAL_50};
    outline: none;
    ${({ showHoverBorder }) => showHoverBorder && `border-top: 4px solid ${COLORS.HALO_BLUE};`}
    box-shadow: ${STYLES.SHADOW_C};
  }

  &:nth-of-type(3n) .Select__menu {
    right: 0;
  }
  ${WINDOW_DIMENSIONS.EXTRA_WIDE_MEDIA_QUERY} {
    &:nth-of-type(3n) .Select__menu {
      right: auto;
    }
    &:nth-of-type(4n) .Select__menu {
      right: 0;
    }
  }
  ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
    &:nth-of-type(3n) .Select__menu {
      right: auto;
    }
    &:nth-of-type(2n) .Select__menu {
      right: 0;
    }
  }
  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    &:nth-of-type(3n) .Select__menu {
      right: 0;
    }
    & .Select__menu {
      right: 0;
    }
  }
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    &:nth-of-type(3n) .Select__menu {
      right: 0;
    }
    & .Select__menu {
      right: 0;
    }
  }
`;
