export enum RfpPartnershipModel {
  SPONSORED_RESEARCH = "SPONSORED_RESEARCH",
  GIFT = "GIFT",
  CO_DEVELOPMENT = "CO_DEVELOPMENT",
  EQUITY_INVESTMENT = "EQUITY_INVESTMENT",
  SUPPLY_PURCHASE = "SUPPLY_PURCHASE",
  LICENSING = "LICENSING",
  MATERIAL_TRANSFER = "MATERIAL_TRANSFER",
  SERVICES_AGREEMENT = "SERVICES_AGREEMENT",
  CAPSTONE_PROJECT = "CAPSTONE_PROJECT",
}

export const PARTNERSHIP_MODEL_LABELS = {
  [RfpPartnershipModel.SPONSORED_RESEARCH]: "Sponsored research",
  [RfpPartnershipModel.GIFT]: "Gift (Unrestricted grant)",
  [RfpPartnershipModel.CO_DEVELOPMENT]: "Co-development",
  [RfpPartnershipModel.EQUITY_INVESTMENT]: "Equity investment",
  [RfpPartnershipModel.SUPPLY_PURCHASE]: "Supply/purchase",
  [RfpPartnershipModel.LICENSING]: "Licensing",
  [RfpPartnershipModel.MATERIAL_TRANSFER]: "Material transfer",
  [RfpPartnershipModel.SERVICES_AGREEMENT]: "Services agreement",
  [RfpPartnershipModel.CAPSTONE_PROJECT]: "Capstone project",
};

export enum RfpBenefitType {
  SPONSORED_RESEARCH = "sponsored_research",
  GIFT = "gift",
  INVESTMENT = "investment",
  EXPERTISE = "expertise",
  TOOLS_AND_TECHNOLOGY = "tools_and_technology",
  COMPOUNDS_AND_REAGENTS = "compounds_and_reagents",
  FACILITIES_AND_SERVICES = "facilities_and_services",
  DATA = "data",
  NETWORKING = "networking",
  SERVICES_AGREEMENT = "services_agreement",
  PAYMENT = "payment",
  EXPOSURE = "exposure",
  EARLY_ACCESS = "early_access",
  MARKET_ACCESS = "market_access",
}

export enum RfpStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  COMING_SOON = "COMING_SOON",
  IN_EXTENSION = "IN_EXTENSION",
}
