import { Tooltip } from "@components/library";
import { FONTS } from "@constants";
import { RootState } from "@redux/store";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface Props {
  text: ReactNode;
  lines?: number;
  font?: string;
  textWidth?: string;
  withTooltip?: boolean;
  tooltipWidth?: string;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
}

const TruncatedText = ({
  text,
  lines = 1,
  font = FONTS.REGULAR_2,
  textWidth,
  withTooltip = true,
  tooltipWidth,
  tooltipPosition = "top",
}: Props) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const windowWidth = useSelector((state: RootState) => state.window.width);

  const textRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (lines > 1) {
      setIsTruncated(Number(textRef.current?.offsetHeight) < Number(textRef.current?.scrollHeight));
    } else {
      setIsTruncated(Number(textRef.current?.offsetWidth) < Number(textRef.current?.scrollWidth));
    }
  }, [windowWidth]);

  let textElement = (
    <Text ref={textRef} font={font} lines={lines}>
      {text}
    </Text>
  );

  if (isTruncated && withTooltip) {
    textElement = (
      <Tooltip content={text} tooltipWidth={tooltipWidth} position={tooltipPosition}>
        {textElement}
      </Tooltip>
    );
  }

  if (!text) return null;

  return isTruncated ? <Container width={textWidth}>{textElement}</Container> : textElement;
};

export default TruncatedText;

const Container = styled.div`
  width: ${({ width }) => width};
`;
const Text = styled.div`
  ${({ font }) => font};
  ${({ lines }) =>
    // Two different mechanics are used to truncate the text, since text-overflow always truncates mid-word, which
    // causes the text to take up the entire space allotted and is generally desirable, whereas webkit-line-clamp
    // does not. However, with text-overflow, we cannot specify the number of lines allowed
    lines > 1
      ? `
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
        overflow: hidden;
        `
      : `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
  `}
`;
