import { AnyTypeCurrentUser } from "./users/show";

export enum UserRole {
  SCIENTIST = 0,
  SPONSOR = 1,
  UNIVERSITY_ADMIN = 2,
}

export type LoggedOutUser = {
  profile_info: Record<string, never>;
};

// Note: We exclude UnfinishedOnboardingCurrentUser because those users will be redirected to onboarding when accessing the app
export type AnyUser = LoggedOutUser | AnyTypeCurrentUser;
