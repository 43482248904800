// Note: SearchBarDropdown and MultiSelectDropdown are not using this component, though are styled
// to look the same, due to the way React-Select handles multi-select tags

import { Icon } from "@components/library";
import { COLORS, FONTS, STYLES } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";
import { IconName } from "../Icon/Icon";

export type TagTheme = "light" | "dark" | "neutral";

export interface TagProps {
  content: ReactNode;
  iconName?: IconName;
  iconPosition?: "left" | "right";
  size?: "xs" | "sm" | "md";
  font?: string;
  theme?: TagTheme;
  isValid?: boolean;
  onRemove?: (...args: any[]) => any;
  id?: string;
  "data-testid"?: string;
  // Style override props
  backgroundColor?: string;
  color?: string;
  margin?: string | 0;
  padding?: string;
  border?: string;
  width?: string;
  shouldTruncate?: boolean;
}

const ICON_SIZE = {
  xs: "xxs",
  sm: "xs",
  md: "sm",
};

const LEFT_ICON_MARGIN = {
  xs: "0 3px 0 -2px",
  sm: "0 4px 0 0",
  md: "0 6px 0 0",
};

const RIGHT_ICON_MARGIN = {
  xs: "0 0 0 3px",
  sm: "0 0 0 4px",
  md: "0 0 0 6px",
};

const Tag = ({
  content,
  iconName,
  iconPosition = "left",
  size = "sm",
  font,
  theme = "neutral",
  isValid = true,
  onRemove,
  id,
  "data-testid": dataTestId,
  backgroundColor,
  color,
  margin = 0,
  padding,
  border,
  width,
  shouldTruncate = false,
}: TagProps) => (
  <StyledTag
    id={id}
    size={size}
    theme={theme}
    isValid={isValid}
    backgroundColor={backgroundColor}
    color={color}
    font={font}
    margin={margin}
    padding={padding}
    border={border}
    width={width}
    data-testid={dataTestId}
    shouldTruncate={shouldTruncate}
  >
    {iconName && iconPosition === "left" && (
      <Icon
        name={iconName}
        size={ICON_SIZE[size] as any}
        color={color}
        margin={LEFT_ICON_MARGIN[size]}
      />
    )}
    <Content shouldTruncate={shouldTruncate} title={shouldTruncate ? content : undefined}>
      {content}
    </Content>
    {iconName && iconPosition === "right" && (
      <Icon
        name={iconName}
        size={ICON_SIZE[size] as any}
        color={color}
        margin={RIGHT_ICON_MARGIN[size]}
      />
    )}
    {onRemove && (
      <div onClick={onRemove}>
        <Icon name="X" size="xs" color={isValid ? COLORS.BLACK : COLORS.RED} />
      </div>
    )}
  </StyledTag>
);

const BACKGROUND_TYPE = {
  light: COLORS.WHITE,
  dark: COLORS.NEUTRAL_200,
  neutral: COLORS.NEUTRAL_100,
};

const XS_STYLES = `
  height: 16px;
  padding: 0 8px;
  ${FONTS.TAG_MEDIUM_3}
`;

const SM_STYLES = `
  height: 24px;
  padding: 0 14px;
  ${FONTS.TAG_MEDIUM_2}
`;

const MD_STYLES = `
  height: 32px;
  padding: 0 16px;
  ${FONTS.TAG_SEMIBOLD_1}
`;

const StyledTag = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${({ width }) => (width ? width : "fit-content")};
  border: none;
  border-radius: 100px;
  background-color: ${({ backgroundColor, theme, isValid }) =>
    (!isValid && COLORS.RED_100) || backgroundColor || BACKGROUND_TYPE[theme]};
  color: ${({ color, isValid }) => (!isValid && COLORS.RED) || color || COLORS.BLACK};
  ${({ size }) => {
    switch (size) {
      case "xs":
        return XS_STYLES;
      case "sm":
        return SM_STYLES;
      case "md":
        return MD_STYLES;
    }
  }}
  white-space: nowrap;
  margin: ${({ margin }) => margin};
  border: ${({ border }) => border};
  ${({ font }) => font && font};
  ${({ shouldTruncate }) =>
    shouldTruncate &&
    `
    max-width: 100%;
    cursor: default;
  `}

  ${({ padding }) => padding && `padding: ${padding};`}

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`;

const Content = styled.div`
  ${({ shouldTruncate }) => (shouldTruncate ? STYLES.ONE_LINE_ALT : "display: flex")}
`;

export default Tag;
