import { Tag, TagTooltip } from "@components/library";
import { COLORS } from "@constants";
import { RootState } from "@redux/store";
import { ReactElement, cloneElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

interface Props {
  children: ReactElement[];
  maxLines?: number;
  maxTags?: number;
  size?: "xs" | "sm";
}

// TODO: (TGAP4, justinpchang) Consider consolidating this component and TagWrapper

const XMoreWrapper = ({ children, maxLines = 1, maxTags, size = "sm" }: Props) => {
  const width = useSelector((state: RootState) => state.window.width);

  const [invisibleTags, setInvisibleTags] = useState<ReactElement[]>([]);
  const [displayedTags, setDisplayedTags] = useState<ReactElement[]>([]);
  const [debouncedWidth, setDebouncedWidth] = useState(width);

  const uniqueId = uuidv4();

  // First we need to paint the tags to the DOM with added ids in order to be able to getElementById and
  // calculate the offsetWidths.  All of the tags will always exist invisibly so that they can be used for
  // calculations, then another set of just the visible tags will be displayed on top
  useEffect(() => {
    const tagsWithIds: ReactElement[] = [];

    for (let i = 0; i < children.length; i += 1) {
      const tagWithId = cloneElement(children[i], {
        id: `${uniqueId}-${children[i].key}`,
      });

      tagsWithIds.push(tagWithId);
    }

    setInvisibleTags(tagsWithIds);
  }, [children]);

  // We debounce the calculation of the tags when the user resizes in order to avoid large CPU spikes
  let timer: ReturnType<typeof setTimeout>;
  useEffect(() => {
    clearInterval(timer);
    timer = setTimeout(() => setDebouncedWidth(width), 50);
  }, [width]);

  useEffect(() => {
    const firstTag = document.getElementById(invisibleTags[0]?.props.id);
    const firstTagTopOffset = window.pageYOffset + Number(firstTag?.getBoundingClientRect().top);

    const tagsToDisplay: ReactElement[] = [];
    const finalRowTags: ReactElement[] = [];

    // First we select the tags that would fit in the given number of rows if there weren't an "X More" tag
    for (let i = 0; i < invisibleTags.length; i += 1) {
      const currentTag = document.getElementById(invisibleTags[i]?.props.id);
      const currentTagTopOffset =
        window.pageYOffset + Number(currentTag?.getBoundingClientRect().top);

      if (currentTagTopOffset && firstTagTopOffset) {
        // Small tags have a height of 24 and margin-top of 6px
        const currentRow = Math.round((currentTagTopOffset - firstTagTopOffset) / 30 + 1);

        if (currentRow <= maxLines && (!maxTags || tagsToDisplay.length < maxTags)) {
          tagsToDisplay.push(invisibleTags[i]);

          if (currentRow === maxLines) {
            finalRowTags.push(invisibleTags[i]);
          }
        } else {
          break;
        }
      }
    }

    const xMoreTagElement = document.getElementById(`${uniqueId}-x-more`);

    if (xMoreTagElement && tagsToDisplay.length === children.length) {
      xMoreTagElement.style.display = "none";
    } else if (xMoreTagElement) {
      xMoreTagElement.style.display = "inline-flex";

      const finalRowTagWidths: number[] = [];

      for (const tag of finalRowTags) {
        const tagElement = document.getElementById(tag.props.id);

        if (tagElement?.offsetWidth) {
          finalRowTagWidths.push(tagElement.offsetWidth);
        }
      }

      const container = document.getElementById(`${uniqueId}-container`);
      const containerWidth = Number(container?.offsetWidth);

      let remainingTagsCount = children.length - tagsToDisplay.length;

      for (let i = finalRowTagWidths.length - 1; i >= 0; i -= 1) {
        // Tags have a margin-right of 8px
        const finalRowTotalTagWidth = finalRowTagWidths.reduce((a, b) => a + b + 8) + 8;

        if (xMoreTagElement) {
          xMoreTagElement.textContent = `${remainingTagsCount} more`;
        }

        const xMoreTagWidth = xMoreTagElement.offsetWidth;

        // There seems to be a CSS rounding issue which is solved by subtracting an extra px for each
        // tag in the final row (i + 1)
        if (finalRowTotalTagWidth + xMoreTagWidth > containerWidth - (i + 1)) {
          remainingTagsCount += 1;
          finalRowTagWidths.pop();
          tagsToDisplay.pop();
        } else {
          break;
        }
      }
    }

    setDisplayedTags(tagsToDisplay);
  }, [invisibleTags, debouncedWidth]);

  const hiddenTags = children.slice(displayedTags.length);

  return (
    <Container id={`${uniqueId}-container`} maxLines={maxLines}>
      <InvisibleTags>{invisibleTags}</InvisibleTags>
      {displayedTags}
      {children.length > 0 && (
        <TagTooltip tags={hiddenTags}>
          {/* This needs to be in its own div to prevent the not(:last-of-type) styles */}
          <XMore>
            <Tag
              id={`${uniqueId}-x-more`}
              key="x-more"
              size={size}
              theme={children[0].props.theme}
              color={COLORS.NEUTRAL_400}
              content=""
              margin="0"
            />
          </XMore>
        </TagTooltip>
      )}
    </Container>
  );
};

export default XMoreWrapper;

const Container = styled.div`
  display: flex;
  flex-wrap: ${({ maxLines }) => (maxLines > 1 ? "wrap" : "nowrap")};
  position: relative;
  margin-top: -6px;
  & > div {
    margin-top: 6px;
  }
`;
const InvisibleTags = styled.div`
  visibility: hidden;
  position: absolute;
  top: -6px;
  & > div {
    margin-top: 6px;
  }
`;
const XMore = styled.div`
  display: flex;
  &:hover {
    cursor: default;
  }
`;
